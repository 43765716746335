<template>
	<div class="browser-select">
		<div class="browser-select--label-checkbox" v-for="(display, value) in availableBrowsers" :key="value">
			<input
				type="checkbox"
				:id="`${value}-checkbox`"
				:checked="selectedBrowsers.includes(value)"
				:value="value"
				:disabled="selectedBrowsers.includes(value) && selectedBrowsers.length === 1"
				@input="toggleBrowserSelected(value)"
			/>
			<label :for="`${value}-checkbox`">{{ display }}</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "BrowserSelect",
	props: {
		value: { type: [String, null], default: null },
	},
	data() {
		return {
			availableBrowsers: {
				chrome: '🪩 Chrome',
				edge: '🌊 Edge',
				firefox: '🦊 Firefox',
				safari: '🧭 Safari',
			},
			selectedBrowsers: [],
		}
	},
	watch: {
		selectedBrowsers() {
			let newValue = null
			// If we have all browsers selected then keep as null since we don't need to specify
			if (this.selectedBrowsers.length !== Object.keys(this.availableBrowsers).length) {
				newValue = this.selectedBrowsers.join(', ')
			}
			this.$emit('input', newValue)
		},
	},
	mounted() {
		// If the value doesn't exist all browsers are being used, so add them all to the list
		// Otherwise split the value as it will be a comma separated string
		if (!this.value) this.selectedBrowsers = Object.keys(this.availableBrowsers)
		else this.selectedBrowsers = this.value.split(', ')
	},
	methods: {
		toggleBrowserSelected(browser) {
			// Remove the value from the list if it already exists, otherwise add it to the list
			const selectedBrowserIndex = this.selectedBrowsers.indexOf(browser)
			if (selectedBrowserIndex !== -1) this.selectedBrowsers.splice(selectedBrowserIndex, 1)
			else this.selectedBrowsers.push(browser)
		}
	}
}
</script>

<style scoped>
.browser-select { display: flex; flex-wrap: wrap; align-items: center; gap: 1.5rem; }
	.browser-select--label-checkbox { display: flex; align-items: center; gap: 0.5rem; }
		.browser-select--label-checkbox input { margin: 0; }
		.browser-select--label-checkbox label { white-space: nowrap; }
</style>
