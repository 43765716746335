<template>
	<section v-if="user" >
		<div class="block">
			<div class="container">
				<div class="row deleted" v-if="user.deleted">
					<div class="col"><h3>Deleted On: {{ formatDate(user.deleted_on) }}</h3></div>
				</div>
				<div class="row">
					<div class="col-4"><router-link :to="`/users/${user.id}`"><h4 class="u--color-primary">{{ user.role }}: {{ user.full_name }}</h4></router-link></div>
					<div class="col-3"><h4>Accepted Invite: {{ user.invite_pending !== false ? "&#x10102;" : "&#x2713;" }}</h4></div>
					<div class="col-5"><h4>Added On: {{ formatDate(user.added_on) }}</h4></div>
				</div>
				<hr/>
				<form @submit.prevent="save">
					<div class="row u--padding-top-5">
						<div class="col">
							<label for="fullName">Full Name</label>
							<input type="text" name="fullName" v-model="user.full_name"><br>
						</div>
						<div class="col">
							<label for="displayName">Display Name</label>
							<input type="text" name="displayName" v-model="user.display_name"><br>
						</div>
						<div class="col">
							<label class="u--padding-right-5">Change Role</label>
							<select v-model="user.role_uuid">
								<option value="e8a0e04a-34f1-4f47-9473-41c9830d7886">Admin</option>
								<option value="6014caaa-7bb7-4245-a867-1eebc900e5dd">Member</option>
							</select>
						</div>
					</div>
					<div class="row u--padding-top-5">
						<div class="col">
							<label for="email">Primary Email</label>
							<input name="email" class="email-input" v-model="user.email"/>
						</div>
					</div>
					<div class="row" v-if="emails.length > 0">
						<div class="col">
							<table>
								<tr>
									<th>Email</th>
									<th>Deleted</th>
									<th>Main Email</th>
								</tr>
								<tr v-for="email in emails" :key="email.email">
									<td>{{ email.email }}</td>
									<td>{{ email.deleted !== true ? "&#x10102;" : "&#x2713;" }}</td>
									<td>{{ email.is_default !== true ? "&#x10102;" : "&#x2713;" }}</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="row u--padding-top-5 hidden">                       
						<div class="col"><button @click.prevent="save">Save</button></div>
						<div class="col" v-if="user.invite_pending"><button class="button-secondary" @click.prevent="reInvite">Re-Invite</button></div>
						<div class="col"><button class="u--accent-bg-danger" @click.prevent="deleteUser">{{ user.deleted ? 'Undelete': 'Delete' }}</button></div>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import util from '@/util'

export default {
	name: 'teamUser',
	props: {
		user: Object,
		teamUuid: String
	},
	data() {
		return {
			emails: [],
			copiedUser: {}
		}
	},
	watch: {
		user() {
			this.emails = []
			if (this.user && this.user.id) this.loadEmails()
		}
	},
	methods: {
		loadEmails() {
			this.$http.get(`/users/${this.user.id}/emails`)
				.then((response) => {
					this.emails = response.data.emails
				})
				.catch(function(error) {
					console.log(error)
				})
		},
		save() {
			console.log(this.user)
			if (this.user.new_user){
				console.log('Add')
				this.user.fullName = this.user.full_name
				this.user.roleUuid = this.user.role_uuid
				// /team/user
				//https://api-preview.momentumdash.com/team/users
				this.$http.post(`/team/${this.teamUuid}/user`, this.user)
					.then((response) => {
						console.log(response)
					})
					.catch(function(error) {
						console.log(error)
					})
			} else {
				console.log('Update')
				//'/team/users/d8c2c249-d354-43dc-9ceb-0eff56009bae'
			}
		},
		reInvite() {
			console.log('reInvite')
		},
		deleteUser() {
			///team/users/{user_uuid:Guid}
			console.log('deleteUser')
		},
		formatDate(date){
			return util.formatDate(date)
		}
	}
}
</script>

<style scoped>
.block-full-width { width: 100%;}
    input { padding-left: 5px; border: solid 1px white !important; color: #666; }
    select { background: white;}
    .deleted { color:red; text-transform: uppercase; }
	.hidden { display: none;}

    .u--padding-right-5 { padding-right: 5px; }
    .u--padding-top-5 { padding-top: 5px; }
    .u--color-white {color: white}

    .email-input { width: 100%; background: transparent;}
</style>