<template>
	<section class="detail notification-detail">
		<notification-form
			:notification="notification"
			:notification-form-title="title"
			:error-message="errorMessage"
			:in-progress="inProgress"
			@viewClosed="closeView"
			@saveClick="updateNotification"
		>
			<div v-if="notification.active" class="col banner-active">
				<span>Live</span>
			</div>
			<div v-else class="col banner-inactive">
				<span>Off Air</span>
			</div>
			<div class="button-row">
				<button v-if="notification.active" title="Set Notification Off Air" class="button-secondary u--border-danger u--color-danger" @click.prevent="setNotificationActive(false)">
					Set Off Air
				</button>
				<button v-else title="Set Notification Live" class="button-secondary u--border-success u--color-success" @click.prevent="setNotificationActive(true)">
					Set Live
				</button>
				<button title="Preview" class="button-secondary" @click.prevent="previewNotification">
					<i v-if="previewed" class="fa fa-check"></i>
					Preview
				</button>
				<div class="user-id-form">
					<momentum-team-member-select v-model="userId" />
					<button
						title="send notification to user"
						class="button-secondary"
						@click.prevent="sendNotificationToUser"
						:disabled="!userId"
					>
						Preview to User
					</button>
				</div>
				<button title="Copy notification" class="button-secondary" @click.prevent="copyNotification">
					Duplicate
				</button>
				<button title="Delete" class="button-secondary u--border-danger u--color-danger" @click.prevent="deleteNotification">
					Delete
				</button>
			</div>
		</notification-form>
	</section>
</template>



<script>
import NotificationForm from './NotificationForm'
import _ from 'lodash'
import MomentumTeamMemberSelect from '@/components/global/MomentumTeamMemberSelect.vue'
export default {
	name: 'notificationDetail',
	components: {
		MomentumTeamMemberSelect,
		NotificationForm
	},
	data() {
		return {
			errorMessage: null,
			inProgress: false,
			activeId: null,
			previewed: false,
			previewedTo: false,
			notification: null,
			originalNotification: null,
			title: '',
			statsTitle: '',
			userId: undefined,
		}
	},
	store: ['notifications', 'copiedNotification'],
	created() {
		this.activeId = Number(this.$route.params.id)
		this.setNotification()
	},
	watch: {
		'$route'() {
			this.activeId = Number(this.$route.params.id)
			this.setNotification()
		}
	},
	methods: {
		setNotification(){
			this.originalNotification = this.notifications.find(x => x.id === this.activeId)
			this.notification = _.cloneDeep(this.originalNotification)
			this.title = `${this.notification.title}`
			this.statsTitle = `Notification Stats: ${this.notification.title}`
		},
		closeView(arg) {
			this.$emit('viewClosed', arg)
		},
		copyNotification (e) {
			e.preventDefault()
			let copy = _.cloneDeep(this.notification)
			copy.active = false
			copy.sent = false
			this.copiedNotification = copy
			this.closeView({notificationCopied:true})
		},
		deleteNotification() {
			this.$http.delete('/admin/notifications/' + this.notification.id).then(() => {
				const notificationIndex = this.notifications.indexOf(this.originalNotification)
				this.notifications.splice(notificationIndex, 1)
				this.closeView()
			}).catch((error) => {
				this.errorMessage = 'There was an error deleting the notification. You are not able to delete sent notifiications.'
				console.log(error)
			})
		},
		previewNotification() {
			if (this.previewing) return;
			this.previewed = false;
			const requestParams = { timeout: 40000 }
			this.$http.post('/admin/notifications/' + this.notification.id + '/users/me', requestParams)
				.then(() => {
					this.previewed = true
				}).catch((error) => {
					console.log(error)
					this.errorMessage = error.response.data.error
					this.previewed = false
				})
		},
		updateNotification() {
			if (this.inProgress) return;
			this.inProgress = true
			this.$http.patch('/admin/notifications/' + this.activeId, this.notification)
				.then(() => {
					this.inProgress = false
					const notificationIndex = this.notifications.indexOf(this.originalNotification)
					this.notifications.splice(notificationIndex, 1, this.notification)
					this.closeView()
					this.$emit('get-notifications')
				}).catch((error) => {
					console.log(error)
					this.errorMessage = error.response.data.error
					this.inProgress = false
				})
		},
		sendNotificationToUser() {
			if (this.previewing) return;
			this.previewedTo = false;
			const requestParams = { timeout: 40000, userId: this.userId }
			this.$http.post('/admin/notifications/' + this.notification.id + '/users', requestParams)
				.then(() => {
					this.previewedTo = true
				}).catch((error) => {
					console.log(error)
					this.errorMessage = error.response.data.error
					this.previewedTo = false
				})
		},
		setNotificationActive(active) {
			this.$http.post('/admin/notifications/' + this.notification.id + '/toggleActive')
				.then(() => {
					this.notification.active = active
					const notifications = this.notifications.filter(notification => { return notification.id === this.notification.id })
					notifications[0].active = active
				})
				.catch((error) => {
					console.log(error)
					this.errorMessage = error.response.data.error
					this.previewedTo = false
				})
		},
	}
}
</script>



<style scoped>
	.button-row { display: flex; margin-top: 1rem; overflow: auto; }
		.user-id-form { display: flex; flex-direction: row; align-items: center; flex-grow: 1; margin-left: 20px; }
			.user-id-form .momentum-team-member-select { flex-grow: 1; min-width: 5rem; }

	.banner-active { height: 20px; border-radius: 5px; background: var(--color-accent); color: white; text-align: center; }
	.banner-inactive { height: 20px; border-radius: 5px; background: tomato; color: white; text-align: center; }

	.button-secondary { font-size: 75%; }
</style>
