<template>
	<div class="container">
		<header class="header">
			<h1>Churn</h1>
			<section class="row">
				<button :class="{'button-main': selected===options.weekly}" @click="selected=options.weekly; loadData('weeks=12')">Weekly</button>
				<button :class="{'button-main': selected===options.daily}" @click="selected=options.daily; loadData('days=15')" >Daily</button>
				<vue-json-to-csv class="button" :json-data="churn" csv-title="Churn">Export to CSV</vue-json-to-csv>
			</section>
		</header>

		<section v-if="loaded" class="graph">
			<heatmap
				color="#d92322"
				:data="churn"
				:column-prop="selected.columnProp"
				:column-label="selected.columnLabel"
				row-prop="creation"
				:row-label="selected.rowLabel"
				value-prop="cumulativeRate"
				formatted-value-prop="cumulativePercent"
				value-label="Cumulative Rate"
			/>

		</section>
		<section v-else-if="loading">
			<slot name="loader"></slot>
		</section>

	</div>

</template>

<script>
import Heatmap from "./Heatmap";
import VueJsonToCsv from 'vue-json-to-csv'
import util from "@/util";
export default {
	components: {Heatmap, VueJsonToCsv},
	name: 'churn',
	data() {
		return {
			loaded: false,
			loading: false,
			churn: [],
			selected: null
		}
	},

	created() {
		this.options = {
			daily: {
				columnProp: 'churnDay',
				columnLabel: 'Churn Day',
				rowLabel: 'Registration Day'
			},
			weekly: {
				columnProp: 'churnWeek',
				columnLabel: 'Churn Week',
				rowLabel: 'Registration Week'
			}
		}
	},

	methods: {
		loadData(query) {
			this.loaded = false
			this.loading = true
			this.$http.get(`/admin/instrumentation/churn?${query}`)
				.then((response) => {
					this.churn = response.data
					this.churn.forEach( c => {
						if (query.indexOf('week') < 0 ) c.weekStart = ' '
						else {
							const year = Math.floor(c.creation / 100)
							const week = c.creation % 100
							c.weekStart = util.getStartOfWeek(year, week)
						}
						c.cumulativePercent = (c.cumulativeRate * 100).toFixed(2) + '%'
						c.tooltip = `${c.weekStart}<br> ${c.uninstalls} uninstalls<br> Cumulative Rate: ${c.cumulativePercent} <br> Cohort size: ${c.cohortSize}`
					})
					this.loaded = true
					this.loading = false
				})
				.catch((error) => {
					console.log(error)
					this.loaded = true
					this.loading = false
				})
		}
	}
}
</script>


<style scoped>
	.header button { margin-right: 5px; }

	.graph { text-align: center; }
</style>