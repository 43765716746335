<template>
	<section class="detail icon-detail" :class="{ 'nested': nestedDetail }">
		<div class="detail-wrapper">
			<header class="detail-header">
				<h2></h2>
				<nav class="header-nav">
					<router-link class="header-nav-item toggle-edit" :to="'/icons/' + activeId.toString() + '/edit'"><i class="ion-edit"></i> Edit</router-link><!--

					--><span href="" class="header-nav-item toggle-dropdown" :class="{ 'active': showHeaderDropdown }" @click="showHeaderDropdown = !showHeaderDropdown">
						<span class="toggle">
							<i class="ion-more icon icon-more"></i>
						</span>

						<transition name="dropdown">
							<p class="dropdown" v-if="showHeaderDropdown">
								<router-link class="header-nav-item" :to="'/icons/' + activeId.toString() + '/delete'">Delete</router-link>
							</p>
						</transition>
					</span>
				</nav>
				<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>
			</header>

			<ul>
				<!-- Icon -->
				<li><img :src="icon.url" class="stock-icon"></li>
				<li :class="{ 'deleted': icon.deleted }">
					<div v-if="icon.id" class="stat icon-body">{{ icon.label }}</div>
				</li>

				<!-- Tags -->
				<li class="line" v-if="icons.tags"></li>
				<li class="detail-row-tags" v-if="icon.tags">
					<div class="label">Tags</div>
					{{ icon.tags }}
				</li>

				<!-- Active -->
				<li class="detail-row" @click="activeToggled">
					Active
					<span class="switch" :class="{ 'on': icon.active }"></span>
				</li>

				<!-- Metadata -->
				<li class="half-width">
					<div class="label">ID</div>
					<div class="stat">{{ icon.id }}</div>
				</li>
				<li class="half-width">
					<div class="label">Added</div>
					<!-- <div class="stat">Feb. 10, 2017</div> -->
					<div class="stat">{{ formatDate(icon.created) }}</div>
				</li>

				<!-- Button (deprecated for now) -->
				<li v-if="editing">
					<button class="u--accent-bg">Save</button>
				</li>
			</ul>
		</div>
		<transition name="detail-nested">
			<router-view @closeNested="closeNested"></router-view>
		</transition>
	</section>
</template>



<script>
import {
	mixin as VueFocusMixin
} from 'vue-focus'
import detailsMixins from '../../mixins/detailsMixins'

export default {
	name: 'icon-detail',

	store: ['icons'],

	data() {
		return {
			showHeaderDropdown: false,
			editing: false
		}
	},

	computed: {
		icon() {
			let foundIcon = null
			if (this.icons && this.icons.length > 0) {
				this.icons.some((icon) => {
					if (icon.id == this.activeId) {
						foundIcon = icon
						return true
					}
					return false
				})
			}
			return foundIcon || {
				id: null,
				body: null,
			}
		}
	},

	created: function() {
		this.checkNested()
	},

	mixins: [VueFocusMixin, detailsMixins],

	watch: {
		'$route' () {
			this.checkNested()
		}
	},

	methods: {
		checkNested() {
			this.$route.meta.viewType == 'nestedDetail' ? this.nestedDetail = true : this.nestedDetail = false
			this.activeId = this.$route.params.id
		},

		closeNested() {
			window.location.href = '/#/icons/' + this.activeId
		},

		activeToggled() {
			let previousState = this.icon.active
			this.icon.active = !previousState
			this.$http.patch('/icons/' + this.icon.id, { active: !previousState })
				.catch(error => {
					this.icon.active = previousState
					console.log(error)
				})
		}
	}
}

</script>


<style scoped>
	.stock-icon { height: 45px; background: #222; }
</style>
