<template>
	<section class="detail query-detail" :class="{ 'nested': nestedDetail }">
		<transition name="fade" mode="out-in">

			<section v-if="!loaded" key="1">
				Loading...
				<slot name="loader"></slot>
			</section>

			<section v-else>
				<div class="detail-wrapper">
					<header class="detail-header">
						<h2></h2>
						<nav class="header-nav">
							<span href="" class="header-nav-item toggle-dropdown" :class="{ 'active': showHeaderDropdown }"
								@click="showHeaderDropdown = !showHeaderDropdown">
								<span class="toggle">
									<i class="ion-more query query-more"></i>
								</span>

								<transition name="dropdown">
									<p class="dropdown" v-if="showHeaderDropdown">
										<vue-json-to-csv :json-data="csvResults" :csv-title="csvTitle">Export to CSV</vue-json-to-csv>
										<span @click="writeToJson">Export to JSON</span>
									</p>
								</transition>
							</span>
						</nav>
						<i class="detail-header-query hide detail-hide" @click="closeView">✕</i>
						<span class="u--padding-left-5">Count: {{ results.length }}</span>
					</header>
					<table class="query-list">
						<tr class="heading">
							<th v-for="(key, index) in keys" :key="index + key" @click="sortBy(key)">
								<span v-if="reverse"> ▲</span>
								{{ key }}
								<span v-if="!reverse"> ▼</span>
							</th>
						</tr>
						<tr v-for="(result, index) in results" :key="index">
							<td v-for="(key, index) in keys" :key="index + key">
								<span>{{ result[key] }}</span>
							</td>
						</tr>
					</table>

				</div>
			</section>
		</transition>
	</section>
</template>

<script>
import detailsMixins from '../../mixins/detailsMixins'

const dayjs = require('dayjs')
import VueJsonToCsv from 'vue-json-to-csv'
import {mixin as VueFocusMixin} from 'vue-focus'

export default {
	name: 'query-detail',
	store: ['queries'],
	components: { VueJsonToCsv },
	data() {
		return {
			reverse: false,
			showHeaderDropdown: false,
			loaded: false,
			results: []
		}
	},
	computed: {
		csvResults: function(){
			if (this.results) {
				// deep copy
				let raw_response_without_nested_json = JSON.parse(JSON.stringify(this.results))
				raw_response_without_nested_json.forEach(raw_response => {
					delete raw_response.completed_onboarding_steps
				})
				return raw_response_without_nested_json
			} else {
				return []
			}
		},
		csvTitle: function(){
			if (this.query) return `${this.query.name}[${dayjs().format()}]`
			else return `[Admin].${dayjs().format()}`
		},
		keys: function () {
			if (this.results.length > 0) return Object.keys(this.results[0])
			return []
		},
		query: function () {
			let foundQuery = null
			if (this.queries && this.queries.length > 0) {
				this.queries.some((query) => {
					if (query.id == this.activeId) {
						foundQuery = query
						return true
					}
					return false
				})
			}
			return foundQuery || {
				id: null,
				body: null,
			}
		}
	},
	created() {
		this.checkNested()
		this.$http.get(this.$route.query.apicall)
			.then((response) => {
				this.results = response.data
				this.loaded = true
			})
			.catch((error) => {
				console.log(error)
				this.loaded = true
			})
	},
	mixins: [VueFocusMixin, detailsMixins],
	watch: {
		'$route'() {
			this.checkNested()
		}
	},
	methods: {
		writeToJson(){
			let uri = 'data:application/json;charset=utf-8,' + '\uFEFF' + encodeURIComponent(JSON.stringify(this.results))

			let link = document.createElement('a')

			link.id = 'json-' + this.query.id
			link.href = uri

			document.body.appendChild(link)

			document.getElementById(link.id).style.visibility = 'hidden'
			document.getElementById(link.id).download = this.csvTitle + '.json'

			document.body.appendChild(link)
			document.getElementById(link.id).click()

			setTimeout(function () {
				document.body.removeChild(link)
			})
		},

		checkNested() {
			this.$route.meta.viewType === 'nestedDetail' ? this.nestedDetail = true : this.nestedDetail = false
			this.activeId = this.$route.params.id
		},

		closeView() {
			this.$router.push({path: '/queries'})
		},

		activeToggled() {
			let previousState = this.query.active
			this.query.active = !previousState
			this.$http.patch('/queries/' + this.query.id, {
				active: !previousState
			})
				.catch((error) => {
					this.query.active = previousState
					console.log(error)
				})
		},

		sortBy(sortKey) {
			this.reverse = !this.reverse;
			this.results.sort(this.compareValues(sortKey, this.reverse))
		},

		compareValues(key, order) {
			return function innerSort(a, b) {
				if (!Object.hasOwn(a, key) || !Object.hasOwn(b, key)) {
					return 0
				}

				const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
				const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

				let comparison = 0
				if (varA > varB) {
					comparison = 1
				} else if (varA < varB) {
					comparison = -1
				}
				return ((order) ? (comparison * -1) : comparison);
			};
		}
	}
}

</script>



<style scoped>

	.detail { width: 100%; }

	.detail-hide { cursor: pointer;}

	.u--padding-left-5 { padding-left: 5px; }

</style>
