import Vue from "vue";

const FileUploader = Vue.extend({
	methods: {
		submitFile: async function(file) {
			let image = {
				uploading: false,
				upload_completed: false,
				fileprogress: 0,
				filename: file.name,
				type: file.type,
				size: file.size
			};
			try {
				let getUrlResponse = await this.$http.post("/admin/blogs/image", image);
				let data = getUrlResponse.data;
				image.image_download_uri = data.image_download_uri;
				image.image_upload_uri = data.image_upload_uri;
				image.file_uuid = data.file_uuid;
				image.blog_image_id = data.blog_image_id;
				if (window.editor && window.editor.configuration && window.editor.configuration.imageIds) window.editor.configuration.imageIds.push(data.blog_image_id)
				return image;
			} catch (error) {
				return null;
			}
		},
		uploadBlock: async function(file, blockId, requestData, uploadUri) {
			let uri = uploadUri + "&comp=block&blockid=" + blockId;
			try {
				await this.$http.put(uri, requestData, {
					headers: {
						"x-ms-blob-type": "BlockBlob",
						"x-ms-blob-content-type": file.type
					}
				});
				return true;
			} catch (error) {
				return false;
			}
		},
		getAndUploadNextBlock: async function(
			file,
			blockIds,
			currentFilePointer,
			totalBytesRemaining,
			uploadUrl
		) {
			let blockIdPrefix = "block-";

			let maxBlockSize = 8 * 2048 * 2048;
			let fileContent = file.slice(
				currentFilePointer,
				currentFilePointer + maxBlockSize
			);
			let blockId = blockIdPrefix + this.pad(blockIds.length, 6);

			blockIds.push(window.btoa(blockId));
			let reader = new window.FileReader();
			reader.readAsArrayBuffer(fileContent);
			let blockUploaded = false;
			reader.onloadend = async e => {
				if (e.target.readyState === window.FileReader.DONE) {
					let requestData = new Uint8Array(e.target.result);
					await this.uploadBlock(
						file,
						window.btoa(blockId),
						requestData,
						uploadUrl
					);
					blockUploaded = true;
				}
			};
			while (!blockUploaded) {
				await new Promise(r => setTimeout(r, 500));
			}
			currentFilePointer += maxBlockSize;
			totalBytesRemaining -= maxBlockSize;
			if (totalBytesRemaining < maxBlockSize)
				maxBlockSize = totalBytesRemaining;
			if (totalBytesRemaining < 0) return null;

			return blockId;
		},
		commitBlockList: async function(file, blockIds, uploadUri) {
			let uri = uploadUri + "&comp=blocklist";
			let requestBody =
				"<?xml version='1.0' encoding='utf-8'?><BlockList>";
			let i;
			for (i = 0; i < blockIds.length; i++) {
				requestBody += "<Latest>" + blockIds[i] + "</Latest>";
			}
			requestBody += "</BlockList>";
			try {
				await this.$http.put(uri, requestBody, {
					headers: {
						"x-ms-blob-content-type": file.type,
						"Content-Type": "application/xml"
					}
				});
				return true;
			} catch (error) {
				return false;
			}
		},
		patchFile: async function(image) {
			try {
				await this.$http.patch(`files/${image.file_uuid}?container=blogimages`, { upload_completed: true });
				return true;
			} catch (error) {
				return false;
			}
		},
		pad: function(number, length) {
			let str = "" + number;
			while (str.length < length) {
				str = "0" + str;
			}
			return str;
		}
	}
});

export default new FileUploader();
