<template>
	<section class="detail submission-detail">
		<div class="detail-wrapper">
			<header class="detail-header">
				<i class="detail-header-icon hide detail-hide" @click="close">✕</i>
			</header>

			<section>
				<h1>{{ submission.location }}</h1>
				<img class="photo" :src="submission.preview_url" @click="openImage"/>
			</section>

			<section>
				<h4><span class="u--small-grey-text">SUBMITTED:</span> <timeago :auto-update="true" :datetime="submission.created"></timeago></h4>
				<h4><span class="u--small-grey-text">LOCATION:</span> {{ submission.location }}</h4>
				<h4><span class="u--small-grey-text">FILENAME:</span> {{ submission.original_filename }}</h4>
				<h4><span class="u--small-grey-text">PHOTOGRAPHER:</span> {{ submission.photographer_name }}</h4>
				<h4><span class="u--small-grey-text">PHOTOGRAPHER EMAIL:</span> {{ submission.photographer_email }}</h4>
			</section>

			<section v-if="!submission.deleted" class="row">
				<button class="col btn-approve" @click="setStatus('approve')">Approve</button>
				<button class="col u--accent-bg-danger" @click="setStatus('reject')">Reject</button>
			</section>

		</div>
		<transition name="detail-nested">
			<router-view @closeNested="close"></router-view>
		</transition>
	</section>
</template>



<script>
export default {
	name: 'submission-detail',
	store: ['submissions'],
	data() {
		return {
			uuid: null,
			submission: null,
		}
	},
	created() {
		this.uuid = this.$route.params.id
		this.submission = this.submissions.find(x => x.uuid == this.uuid)
	},
	methods: {
		close() {
			this.$emit('viewClosed')
		},
		openImage() {
			window.open(this.submission.large_url)
		},
		setStatus(status) {
			this.$http.post('/submissions/' + this.submission.uuid + `/status?status=${status}`)
				.then(() => {
					const index = this.submissions.indexOf(x => x.uuid == this.uuid)
					this.submissions.splice(index, 1)
					this.close()
				}) 
		},
	}
}

</script>



<style scoped>
	.detail { width: 40%; }

	.photo { width: 100%; border-radius: 5px; cursor: pointer; }

	h4 { color: black; text-transform: initial !important; }

	.btn-approve { margin-right: 10px;}
</style>
