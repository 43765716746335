<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"/>
			<h2>Transfer Subscription</h2>
			<!--<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>-->
		</header>

		<i class="ion-arrow-swap icon icon-right icon-swap" title="Swap From/To"/>
		<h3>From</h3>
		<strong>{{ user.name }}</strong>
		<br>{{ user.email }}
		<br>{{ user.created }}

		<h3>To</h3>
		<form class="search-form">
			<i class="icon icon-search ion-ios-search-strong" @click.prevent="doSearch"/>
			<input type="text" name="searchEmail" v-model.trim="searchEmail" placeholder="Search" class="search">
			<input type="submit" v-model="searchLabel" class="u--accent-bg" @click.prevent="doSearch" v-bind:disabled="searchDisabled">
			<div v-if="foundUser.notFound"><strong>No user found</strong></div>
			<strong>{{ foundUser.name }}</strong>
			<br>{{ foundUser.email }}
			<br>{{ foundUser.created }}
		</form>
		<form class="search-form">

			<input v-validate="'required|numeric'" type="text" name="helpticket" placeholder="Help Ticket #" class="notes" v-model.trim="helpTicketId">

			<input v-validate="'required'" type="text" name="notes" placeholder="Notes" class="notes" v-model.trim="notes">

			<input type="submit" v-model="transferLabel" class="u--accent-bg" @click.prevent="doTransfer" v-bind:disabled="transferDisabled">
			<span>{{ errorMessage }}</span>

		</form>
	</section>
</template>


<script>
import {
	mixin as VueFocusMixin
} from 'vue-focus'

export default {
	name: 'user-detail-transfer',

	store: ['users'],

	data() {
		return {
			searchEmail: null,
			notes: null,
			helpTicketId: null,
			activeId: null,
			errorMessage: null,
			searchLabel: 'Find User',
			searchDisabled: false,
			transferLabel: 'Transfer',
			transferDisabled: true,
			foundUsers: []
		}
	},

	computed: {
		submitLabel: function() {
			return 'Transfer'
		},

		user() {
			return this.users[this.activeId] || {
				name: null
			}
		},

		foundUser() {
			if (this.foundUsers.length > 0) {
				return this.foundUsers[0]
			}
			return {
				notFound: true
			}
		}

	},

	created: function() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	mixins: [VueFocusMixin],

	watch: {
		'$route' () {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		doSearch() {
			let self = this
			let error = null
			if (!this.searchEmail || this.searchEmail.length < 8) error = 'You must enter a valid email'
			if (error) {
				this.errorMessage = error
				return
			}
			this.errorMessage = null
			this.searchLabel = 'Searching...'
			this.searchDisabled = true

			let searchParams = {
				params: {
					email: this.searchEmail
				}
			}
			this.$http.get('/users', searchParams)
				.then(function(response) {
					if (response && response.data) {
						self.foundUsers = response.data
					} else {
						self.foundUsers = []
					}
					self.searchDisabled = false
					self.searchLabel = 'Search'
					self.transferDisabled = false
				})
				.catch(function(error) {
					self.searchDisabled = false
					self.searchLabel = 'Search'
					console.log(error)
				})
		},

		doTransfer() {
			let self = this
			this.$validator.validateAll().then(success => {
				if (!success) {
					// handle error
					console.log('errors - not Transferring' + JSON.stringify(this.$validator.getErrors()))
					this.errorMessage = 'Check the fields'
					return
				}

				console.log('Transferring')
				let error = null
				if (error) {
					this.errorMessage = error
					return
				}
				this.errorMessage = null
				this.transferLabel = 'Transferring...'
				this.transferDisabled = true

				let postData = {
					destination_user_id: this.foundUser.id,
					help_ticket_id: this.helpTicketId,
					comments: this.notes
				}
				this.$http.post('/users/' + this.user.id + '/transfer_stripe_account', postData)
					.then(function(response) {
						if (response.data.success) {
							self.transferLabel = 'Transfer Successful'
							self.transferDisabled = true
						} else {
							self.transferLabel = 'Transfer'
							self.transferDisabled = false
							self.errorMessage = response.error
						}
					})
					.catch(function(error) {
						self.transferLabel = 'Transfer'
						self.transferDisabled = false
						console.log(error)
					})
			}).catch(bag => {
				console.log('errors - not Transferring' + JSON.stringify(bag))
				this.errorMessage = 'Check the fields'
			})
		},

		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		}
	}
}

</script>


<style>
	.detail input.search {
		margin-top: -4px;
	}

	.detail input.notes {}

</style>
