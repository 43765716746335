<template>
	<section class="blocks three-quater-wrapper team-users">
		<div class="block" v-if="!user">
			<table>
				<caption class="table-caption">{{ userCount }} Active Team Users | {{ deletedUserCount }} Deleted Team Users </caption>
				<tr>
					<th>Accepted</th>
					<th>Full Name</th>
					<th>Email</th>
					<th>Role</th>
				</tr>
				<tr v-for="user in users" :key="user.id" :class="{'admin-deleted': user.deleted}" @click="showUser(user)" >
					<td class="accepted">{{ user.invite_pending !== false ? "&#x10102;" : "&#x2713;" }}</td>
					<td>{{ user.full_name }}</td>
					<td>{{ user.email.length > 40 ? `${user.email.substring(0, 37)}...` : user.email }}</td>
					<td>{{ user.role }}</td>
				</tr>
			</table>
			<div hidden><button @click="showUser({new_user: true, role: 'Member'})">+ Add User/Member</button></div>
		</div>
		<team-user :user="user" :team-uuid="teamUuid"></team-user>
	</section>
</template>

<script>
import TeamUser from './TeamUser'
export default {
	name: 'teamUsers',
	props: {
		teamUuid: String,
		userShow: Boolean
	},
	components: {
		TeamUser
	},
	data() {
		return {
			users: [],
			user: null
		}
	},
	created() {
		this.getTeamUsers()
	},
	watch: {
		userShow(newVal) {
			if (newVal === false){
				this.user = null
			}
		}
	},
	computed: {
		deletedUserCount(){
			if (!this.users) return;
			return this.users.filter(x => x.deleted).length
		},
		userCount(){
			if (!this.users) return;
			return this.users.filter(x => !x.deleted).length
		}
	},
	methods: {
		showUser(user) {
			this.user = user
			this.$emit('showingUser')
		},
		getTeamUsers() {
			this.$http.get(`/team/${this.teamUuid}/users`)
				.then(response => {
					this.users = response.data.result
				})
				.catch(error => {
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>
	.admin-deleted { text-decoration: line-through; color: tomato}
	.table-caption { font-size: 12px; font-weight: 600; text-align: left; opacity: 0.8;text-transform: uppercase;white-space: nowrap; }
	td.accepted { text-align: center; line-height: 100%; }
</style>