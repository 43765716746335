import { render, staticRenderFns } from "./ContrastRatio.vue?vue&type=template&id=1ba0475b&scoped=true&"
import script from "./ContrastRatio.vue?vue&type=script&lang=js&"
export * from "./ContrastRatio.vue?vue&type=script&lang=js&"
import style0 from "./ContrastRatio.vue?vue&type=style&index=0&id=1ba0475b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba0475b",
  null
  
)

export default component.exports