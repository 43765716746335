<template>
	<div class="container">
		<header class="header">
			<h1>Conversion</h1>
			<section class="row">
				<button :class="{'button-main': selected===options.monthly}" @click="selected=options.monthly; loadData('months=6')">Monthly</button>
				<button :class="{'button-main': selected===options.weekly}" @click="selected=options.weekly; loadData('weeks=12')">Weekly</button>
				<vue-json-to-csv class="button" :json-data="conversions" csv-title="Conversions">Export to CSV</vue-json-to-csv>
			</section>
		</header>
		
		<section v-if="loaded" class="graph">
			<heatmap color="#69b3a2"
				:data="conversions"
				:column-prop="selected.columnProp"
				:column-label="selected.columnLabel"
				row-prop="creation"
				:row-label="selected.rowLabel"
				value-prop="cumulativeRate"
				formatted-value-prop="cumulativePercent"
				value-label="Cumulative Rate"
			/>

		</section>
		<section v-else-if="loading">
			<slot name="loader"></slot>
		</section>


	</div>

</template>

<script>
import Heatmap from "./Heatmap";
import VueJsonToCsv from 'vue-json-to-csv'
import util from "@/util"
export default {
	components: {Heatmap, VueJsonToCsv},
	name: 'conversion',
	data() {
		return {
			loaded: false,
			loading: false,
			conversions: [],
			selected: null
		}
	},

	created() {
		this.options = {
			monthly: {
				columnProp: 'conversionMonth',
				columnLabel: 'Conversion Month',
				rowLabel: 'Registration Month'
			},
			weekly: {
				columnProp: 'conversionWeek',
				columnLabel: 'Conversion Week',
				rowLabel: 'Registration Week'
			}
		}
	},

	methods: {
		loadData(query) {
			this.loaded = false
			this.loading = true
			this.$http.get(`/admin/instrumentation/conversions?${query}`)
				.then((response) => {
					this.conversions = response.data
					let d = new Date(2021, 0, 1)
					d.setDate(d.getDate()+7-d.getDay())
					this.conversions.forEach( c => {
						c.cumulativePercent = (c.cumulativeRate * 100).toFixed(2) + '%'
						if (query.indexOf('week') < 0 ) c.weekStart = ' '
						else {
							const year = Math.floor(c.creation / 100)
							const week = c.creation % 100
							c.weekStart = util.getStartOfWeek(year, week)
						}
						c.tooltip = `${c.weekStart}<br> ${c.upgrades} upgrades<br> Cumulative Rate: ${c.cumulativePercent} <br> Cohort size: ${c.cohortSize}`
					})
					this.loaded = true
					this.loading = false
				})
				.catch((error) => {
					console.log(error)
					this.loaded = true
					this.loading = false
				})
		}
	}
}
</script>

<style scoped>
	.header button { margin-right: 5px; }

	.graph { text-align: center; }
</style>
