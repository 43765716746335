<template>
	<aside class="sidebar">
		<h1 class="logo">
			<router-link to="/dash"></router-link>
			<img src="../../assets/img/logo.png" alt="Momentum Admin">
		</h1>
		<div class="nav-wrapper">
			<nav>
				<span class="section" v-for="(section, title) in navObject.navItems" :key="title">
					<span class="section-title">{{ title }} </span>
					<router-link :class="{active: navItem.path === path}" v-for="navItem in section" v-bind:to="navItem.path" :key="navItem.label">{{ navItem.label }}</router-link>
				</span>
			</nav>
		</div>
		<user-menu></user-menu>
	</aside>
</template>



<script>
import UserMenu from './UserMenu'
export default {
	name: 'admin-menu',
	data() {
		return {
			navObject: {
				selectedNav: 'dash',
				navItems: {
					customers: [
						{
							path: '/team',
							name: 'team',
							label: 'Teams'
						},
						{
							path: '/users',
							name: 'users',
							label: 'Users'
						},
					],
					growth: [
						{
							path: '/churn',
							name: 'churn',
							label: 'Churn'
						},
						{
							path: '/conversion',
							name: 'conversion',
							label: 'Conversion'
						},
						{
							path: '/experiments',
							name: 'experiments',
							label: 'Experiments'
						},
						{
							path: '/notifications',
							name: 'notifications',
							label: 'Notifications'
						},
					],
					content: [
						{
							path: '/upload-assets',
							name: 'upload-assets',
							label: 'Upload Assets'
						},
						{
							path: '/backgrounds',
							name: 'backgrounds',
							label: 'Backgrounds'
						},
						{
							path: '/blogs',
							name: 'blogs',
							label: 'Blogs'
						},
						{
							path: '/icons',
							name: 'icons',
							label: 'Icons'
						},
						{
							path: '/mantras',
							name: 'mantras',
							label: 'Mantras'
						},
						{
							path: '/quotes',
							name: 'quotes',
							label: 'Quotes'
						},
						{
							path: '/submissions',
							name: 'submissions',
							label: 'Submissions'
						},
						{
							path: '/news',
							name: 'news',
							label: "What's new"
						},
					],
					development: [
						{
							path: '/database',
							name: 'database',
							label: 'Database'
						},
						{
							path: '/feature',
							name: 'feature',
							label: 'Features'
						},
						{
							path: '/queries',
							name: 'queries',
							label: 'Queries'
						},
					],
				}
			}
		}
	},
	components: {
		UserMenu
	},
	store: ['userInfo'],
	computed: {
		path() {
			return this.$route.path
		}
	},
	methods: {
		navClicked(name) {
			this.navObject.selectedNav = name
		}
	}
}
</script>

<style scoped>
	.sidebar nav a	{ padding: 9px 0;}
	.section { padding: 12px 0 12px 0; }
		.section .section-title { margin-bottom: 3px; text-transform: uppercase; font-weight: bolder; color: whitesmoke; text-decoration: underline; }
</style>
