<template>
	<section class="detail" :class="{ 'nested': nestedDetail }">
		<div class="detail-wrapper">
			<header class="detail-header">
				<h2>
					<a v-if="locked" @click="toggleLock" title="Unlock feature set for editing" class="u--cursor"><i class="fa fa-lock"></i></a>
					<a v-else @click="toggleLock" class="u--cursor"><i class="fa fa-unlock u--color-danger"></i></a>
					{{ featureSet.featureset_name }}
				</h2>
				<h2 class="message">{{ message }}</h2>
				<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>
			</header>

			<ul>
				<li class="detail-row-notes">
					<div class="features-row">
						<span class="label">Features</span>
						<button v-if="!locked && !editing" title="Edit" :class="{ 'editing': editing }" @click="editing = !editing" class="button-edit"><i class="fa fa-pencil"></i></button>
						<div v-else-if="!locked && editing && !confirm">
							<button title="Cancel" @click="toggleLock" class="button-edit"><i class="fa fa-times"></i></button>
							<button title="Save" class="button-save" @click.prevent="confirm = true" v-bind:disabled="inProgress"><i class="fa fa-save"></i></button>
						</div>
						<div v-else-if="confirm" class="confirm">
							<p class="u--action message">You are about to save feature set {{ featureSet.featureset_name }}. Are you sure?</p>
							<div class="row">
								<div class="col">
									<button @click.prevent="updateFeatures" class="u--accent-bg-danger"><i class="fa fa-exclamation"></i> Yes</button>
								</div>
								<div class="col">
									<button @click="confirm = false" class="u--accent-bg-success"><i class="fa fa-arrow-left"></i> No</button>
								</div>
							</div>
						</div>
					</div>
					<ul v-for="feature in features.active" class="feature-name" :key="feature.id">
						<li>
							<input v-if="editing" v-model="feature.active" type="checkbox" class="features-edit-checkbox">
							<label class="feature-name">{{ feature.name }}</label>
						</li>
					</ul>
					<div v-if="editing">
						<span class="search">
							<svg class="icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.31 250.31"><path d="M244.19,214.6l-54.38-54.37c-.29-.29-.63-.49-.93-.76a103,103,0,1,0-29.42,29.41c.27.3.47.64.76.93l54.38,54.38a20.92,20.92,0,0,0,29.59-29.59ZM102.91,170.15a67.24,67.24,0,1,1,67.24-67.24A67.24,67.24,0,0,1,102.91,170.15Z" /></svg>
							<input ref="search" v-model="searchInput" placeholder="Search" @keydown.esc.stop="onSearchEsc">
						</span>
						<ul v-for="feature in filteredFeatures" class="feature-name" :key="feature.id">
							<li>
								<input v-model="feature.active" type="checkbox" class="features-edit-checkbox">
								<label class="feature-name">{{ feature.name }}</label>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
import featureManager from '@/managers/featureManager'
import detailsMixins from '../../mixins/detailsMixins'

export default {
	name: 'feature-detail',
	data() {
		return {
			inProgress: false,
			editing: false,
			features: { active: [], inactive: [] },
			searchInput: '',
			locked: true,
			message: '',
			confirm: false
		}
	},
	mixins: [detailsMixins],
	created() {
		featureManager.loadFeatureList()
	},
	watch: {
		'$route': {
			handler() {
				this.checkNested()
			},
			immediate: true
		},
		activeId() {
			this.syncFeatures()
		},
		featureList() {
			this.syncFeatures()
		}
	},
	computed: {
		processLabel() {
			return this.inProgress ? 'Processing...' : 'Submit'
		},
		featureSet() {
			let foundFeatureSet = this.featureSets.find(featureSet => featureSet.id === this.activeId)
			if (foundFeatureSet) {
				this.locked = foundFeatureSet.locked
			} else {
				foundFeatureSet = {
					id: null,
					featureset_name: null,
					created_by: null,
					modified_by: null,
					features: null,
					locked: true
				}
			}
			return foundFeatureSet
		},
		filteredFeatures() {
			return (!this.searchInput.length) ? this.features.inactive : this.features.inactive.filter((feature) => feature.name.toLowerCase().match(this.searchInput.toLowerCase()))
		},
		featureList() {
			return featureManager.allFeatures
		},
		featureSets() {
			return featureManager.allFeatureSets
		}
	},
	methods: {
		checkNested() {
			this.nestedDetail = this.$route.meta.viewType === 'nestedDetail'
			this.activeId = this.$route.params.id
			this.forceLock()
		},
		closeView() {
			if (!this.locked) this.toggleLock()
			this.$emit('viewClosed')
			this.clearSearch()
			this.confirm = false
		},
		updateFeatures() {
			this.inProgress = true

			const updateActiveFeatureList = this.features.active.filter(feature => feature.active).map(feature => feature.id)
			const updateInactiveFeatureList = this.features.inactive.filter(feature => feature.active).map(feature => feature.id)

			let updateFeatureList = updateActiveFeatureList.concat(updateInactiveFeatureList)
			const requestParams = { timeout: 40000, features: updateFeatureList }
			this.$http.put(`/admin/features/${this.featureSet.id}`, requestParams)
				.then((response) => {
					this.inProgress = false
					if (response.data) {
						this.featureSet.features = response.data
						this.syncFeatures()
						updateFeatureList = []
					}
					this.toggleLock()
				}).catch((error) => {
					this.inProgress = false
					console.log(error)
				})
		},
		syncFeatures() {
			let active = []
			let	inactive = []
			if (!this.featureSet.features) {
				inactive = this.featureList
			} else {
				this.featureList.forEach(feature => {
					let match = this.featureSet.features.filter(f => f.id === feature.id)
					if (match.length) {
						feature.active = true
						active.push(feature)
					} else {
						feature.active = false
						inactive.push(feature)
					}
				})
			}
			this.features = { active, inactive }
		},
		onSearchEsc() {
			this.searchInput() ? this.clearSearch() : this.editing = false
		},
		clearSearch() {
			this.searchInput = ''
		},
		toggleLock () {
			this.$http.post(`/admin/features/${this.featureSet.id}/togglelock?unlock=${this.locked}`)
				.then(() => {
					if (this.locked){
						this.message = 'Unlocked for five minutes'
						this.locked = false
						this.confirm = false
						this.clearSearch()
					} else {
						this.message = ''
						this.locked = true
						this.editing = false
						this.confirm = false
						this.clearSearch()
					}
				})
				.catch(() => {
					this.message = 'Unable to unlock, contact support'
				})
		},
		forceLock() {
			this.$http.post(`/admin/features/${this.featureSet.id}/togglelock?unlock=false`)
				.then(() => {
					this.message = ''
					this.locked = true
					this.editing = false
					this.confirm = false
					this.clearSearch()
				})
				.catch(() => {
					this.message = 'Unable to lock, contact support'
				})
		}
	}
}
</script>


<style scoped>
	.detail { width: 50%; }

	.features-row { vertical-align: middle; line-height: 40px; }
		button { float: right; cursor: pointer; }
		.features-row .button-edit { background-color: tomato; width: 50px; }
		.features-row .button-save { background-color: lightgreen; width: 50px; margin-right: 5px; }

	.features-edit-checkbox { margin: 0 5px 0 0; }

	hr { opacity: 0.5; }

	.confirm { margin-bottom: 5px; }

	.message { margin: 0 0 0 5px; color: tomato; font-weight: bolder; }
	.u--action { text-align: center; margin-bottom: 3px; }

	.search { --gutter-size: 9px; position: relative; flex-grow: 1; display: inline-block; width: 100%; margin-bottom: 20px; }
		.search .icon-search { height: 15px; position: absolute; top: 50%; left: 9px; transform: translateY(-50%); opacity: 0.6; }
		.search input { margin: 0; padding: var(--gutter-size) var(--gutter-size) var(--gutter-size) 30px; box-shadow: none; background-color: transparent; border-radius: 10px; width: 100%;}
			.search input::placeholder { opacity: 0.7; }
</style>
