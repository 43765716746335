<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
			<h2>Cached Data</h2>
			<div class="header-action">
				<button class="button" @click="flushCache">{{ flushLabel }}</button>
				<div>{{ message }}</div>
			</div>
		</header>
		<section class="cached-data-section">
			<transition name="fade">
				<div v-if="loading" class="loading">Loading...</div>
				<div v-else-if="!cachedFeatures && !cachedUserInfo" class="server-data-error">There's an issue loading user cached features. Please try again.</div>
				<section v-else class="blocks">
					<div class="json-chunk-block">
						<button class="link-button json-chunk-button" @click="showJSONChunk = !showJSONChunk">{{ this.showJSONChunk ? 'Hide' : 'Show' }} JSON Chunk</button>
						<transition name="fade">
							<div v-if="showJSONChunk">
								<div class="json-chunk">
									<span class="title">UserInfo: </span>
									<pre>{{ cachedUserInfo }}</pre>
								</div>
								<div class="json-chunk">
									<span class="title">FeatureInfo: </span>
									<pre>{{ cachedFeatures }}</pre>
								</div>
							</div>
						</transition>
					</div>
					<div class="block">
						<span class="title">User Info</span>
						<ul>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Uuid</div>
								</div>
								{{ cachedUserInfo.UserUuid }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Legacy Id</div>
								</div>
								{{ cachedUserInfo.legacy_id }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Active Subscription</div>
								</div>
								{{ cachedUserInfo.active_subscription }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Auth Method</div>
								</div>
								{{ cachedUserInfo.AuthMethod }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Internal</div>
								</div>
								{{ cachedUserInfo.Internal }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Client UTC Offset</div>
								</div>
								{{ cachedUserInfo.clientUTCOffset }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Created</div>
								</div>
								{{ formatDate(cachedUserInfo.created) }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Deleted Date</div>
								</div>
								{{ formatDate(cachedUserInfo.DeletedDate) }}
							</li>
						</ul>
						<span class="title">Instrumentation Info</span>
						<ul>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Is Instrumented</div>
								</div>
								{{ cachedUserInfo.InstrumentThisUser }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Instrumention Info</div>
								</div>
								{{ cachedUserInfo.instrumentation_definitions_json }}
							</li>
						</ul>
						<span class="title">Subscription / Team Info</span>
						<ul>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Plus Start Date</div>
								</div>
								{{ formatDate(cachedUserInfo.plus_start) }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Team Id / Team Uuid</div>
								</div>
								{{ cachedUserInfo.TeamId }} / {{ cachedUserInfo.TeamUuid }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Is Team User</div>
								</div>
								{{ cachedUserInfo.IsTeamUser }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Is Team Admin</div>
								</div>
								{{ cachedUserInfo.IsTeamAdmin }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Invite Pending</div>
								</div>
								{{ cachedUserInfo.invitePending }}
							</li>
						</ul>
						<span class="title">Others</span>
						<ul>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Nosql Db Collection</div>
								</div>
								{{ cachedUserInfo.nosql_db_collection }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Relational Collection Id</div>
								</div>
								{{ cachedUserInfo.RelationalCollectionId }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Expire Tokens Before</div>
								</div>
								{{ cachedUserInfo.ExpireTokensBefore }}
							</li>
						</ul>
					</div>
					<div class="block">
						<span class="title">Features</span>
						<ul class="features-list">
							<li v-for="(feature, key) in cachedFeatures" :key="key" class="feature-alias">{{ feature.feature_alias }}</li>
						</ul>
					</div>
				</section>
			</transition>
		</section>
	</section>
</template>



<script>
import util from '@/util'

export default {
	name: 'user-cached-data',
	store: ['users'],
	data() {
		return {
			activeId: null,
			flushInProgress: false,
			message: null,
			showJSONChunk: false,
			cachedFeatures: null,
			cachedUserInfo: null,
			loading: false
		}
	},

	computed: {
		user() {
			return this.users[this.activeId] || {
				name: null
			}
		},
		flushLabel() {
			return this.flushInProgress ? 'Flushing...' : 'Flush Cached Data'
		}
	},

	created() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
		this.checkCache()
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	watch: {
		'$route' () {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		checkCache() {
			this.loading = true
			this.$http.get(`/admin/user/${this.user.user_uuid}/cache`)
				.then((response) => {
					console.log('', response.data)
					this.cachedFeatures = JSON.parse(response.data.featureInfo)
					this.cachedUserInfo = JSON.parse(response.data.userInfo)
				})
				.catch(error => console.log('error in getting cached user feature data - ', error))
				.finally(() => this.loading = false)
		},

		flushCache() {
			this.flushInProgress = true
			this.$http.post('/users/' + this.activeId + '/flushCacheData', { flushCache: true })
				.then((response) => {
					this.message = 'User data successfully flushed.'
					console.log(response.data)
				})
				.catch(error => this.message = error)
				.finally(() => this.flushInProgress = false)
		},

		handleKeyup(e) {
			if (e.keyCode === 27 || e.key === 27) this.closeView() // escape key
		},

		formatDate(date) {
			return util.formatPacificDate(date)
		},
	}
}
</script>



<style scoped>
	.button { width: 240px; }
	.header-action { margin-bottom: 25px; }

	.cached-data-section { margin-bottom: 2em; }

		.json-chunk-block { width: 100%; margin-bottom: 25px; }
			.json-chunk { margin-top: 15px; }
			.json-chunk .title { font-weight: 500; }
		.feature-alias { margin-bottom: 15px; }
</style>
