<template>
	<div>
		<header class="header">

		</header>

		<section>
			<router-link to="/experiments-old" tag="h1" class="button">Old Experiments</router-link>
			<router-link to="/conversion" tag="h1" class="button">Conversion</router-link>
			<router-link to="/churn" tag="h1" class="button">Churn</router-link>

		</section>

	</div>

</template>

<script>

export default {
	name: 'growth'
}
</script>

<style scoped>
	.button { color: dodgerblue }
</style>
