<template>
	<section>
		<div class="row">
			<div class="col-8" @click="focusHslaInput">
				Contrast Ratio: {{ contrastRatio }}
			</div>
		</div>
		<div class="row">
			<div class="col-1">
				<button class="eyedropper-button" title="Open Eyedropper" @click.prevent="$emit('open-eyedropper')"><i class="fa fa-eyedropper"></i></button>
			</div>
			<div class="col">
				hsl:
				<input
					@focus="$emit('hsl-focused' ,true)"
					@blur="$emit('hsl-focused', false)"
					type="number"
					ref="hslaInput"
					class="hsla-input"
					min="0"
					max="360"
					v-model="selectedColor.hsl[0]"
				/>
				<input
					@focus="$emit('hsl-focused' ,true)"
					@blur="$emit('hsl-focused', false)"
					type="number"
					class="hsla-input"
					min="0"
					max="100"
					v-model="selectedColor.hsl[1]"
				/>
				<input
					@focus="$emit('hsl-focused' ,true)"
					@blur="$emit('hsl-focused', false)"
					type="number"
					class="hsla-input"
					min="0"
					max="100"
					v-model="selectedColor.hsl[2]"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import * as Vibrant from 'node-vibrant'

export default {
	name: "ContrastRatio",
	props: {
		selectedColor: Object
	},
	watch: {
		'selectedColor.hsl'() {
			if (!this.selectedColor.hsl || !this.selectedColor.hsl.length) return
			this.selectedColor.hsla = `hsla(${this.selectedColor.hsl[0]}, ${this.selectedColor.hsl[1]}%, ${this.selectedColor.hsl[2]}%, 0.95)`
		},
	},
	computed: {
		contrastRatio() {
			if (!this.selectedColor || !this.selectedColor.hsla) return 0
			const hslSplit = this.splitHslaValues(this.selectedColor.hsla)
			const color = Vibrant.Util.hslToRgb(Number(hslSplit[0])/360, Number(hslSplit[1])/100, Number(hslSplit[2])/100)
			const relativeLuminosity = this.calculateLuminosity(color[0], color[1], color[2])
			const bodyTextColor = (relativeLuminosity > 0.3) ? '#222' : '#fff'
			const contrastRatio = this.calculateContrastRatio(relativeLuminosity, bodyTextColor)
			const hsl = [hslSplit[0], hslSplit[1], hslSplit[2]]
			this.$set(this.selectedColor, 'hsl', hsl)
			this.selectedColor.hsl = hsl
			this.selectedColor.rgba = `rgba(${color[0].toFixed()}, ${color[1].toFixed()}, ${color[2].toFixed()}, 0.95)`
			this.selectedColor.hex = Vibrant.Util.rgbToHex(color[0], color[1], color[2])
			this.$set(this.selectedColor, 'bodyTextColor', bodyTextColor);
			this.selectedColor.bodyTextColor = bodyTextColor
			this.selectedColor.contrastRatio = contrastRatio
			return contrastRatio
		}
	},
	methods: {
		// https://www.w3.org/WAI/GL/wiki/Relative_luminance
		calculateLuminosity(r, g, b) {
			const rs = this.getXsRGB(r)
			const gs = this.getXsRGB(g)
			const bs = this.getXsRGB(b)
			return (rs * 0.2126 + gs * 0.7152 + bs * 0.0722)
		},
		getXsRGB(x){
			const xs = x / 255
			if (xs <= 0.03928) {
				return xs / 12.92;
			} else {
				return Math.pow(((xs + 0.055 ) / 1.055), 2.4)
			} 
		},
		calculateContrastRatio(luminosity, textColor) {
			const luminosityOfWhite = 1
			const luminosityOfOurBlack = this.calculateLuminosity(34, 34, 34)
			let contrastRatio = 0
			if (textColor === '#fff'){
				contrastRatio = (luminosityOfWhite + 0.05) / (luminosity + 0.05)
			} else {
				contrastRatio = (luminosity + 0.05) / (luminosityOfOurBlack + 0.05)
			}
			return contrastRatio.toFixed(2)
		},
		focusHslaInput() {
			this.$refs.hslaInput.focus()
		},
		splitHslaValues(hsla) {
			if (!hsla) return
			const firstSplit = hsla.split('(')
			if (!firstSplit || !firstSplit[1]) return
			const secondSplit = firstSplit[1].split(')')
			if (!secondSplit || !secondSplit[0]) return
			const split = secondSplit[0]
			if (split.length < 3) return
			const hsl = hsla.includes(',') ? split.split(',') : split.split(' ')
			return [hsl[0].trim(), hsl[1].trim().replace('%', ''), hsl[2].trim().replace('%', '')]
		},
	}
};
</script>

<style scoped>
	.eyedropper-button { background: transparent; color: var(--text-color); padding: 5px; padding-top: 15px; margin: -5px; margin-left: 5px; }

	.hsla-input { padding-left: 0px; padding-bottom: 0; border: none; width: 10%; font-size: inherit; color: var(--preview-text-color); background: transparent; }

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
</style>
