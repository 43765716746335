<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
			<h2>Instrumentation Info</h2>
		</header>
		<section class="cached-data-section">
			<transition name="fade">
				<div v-if="loading" class="loading">Loading...</div>
				<div v-else-if="!instrumentationInfo" class="server-data-error">{{ message }}</div>
				<section v-else class="blocks">
					<div class="block token-info-block">
						<span class="found">Entries found: {{ entryNumber }}</span>
						<table>
							<tr class="heading">
								<th>Created date</th>
								<th>Distinct ID</th>
								<th>Provider</th>
								<th>Provider Instance</th>
								<th>Active</th>
							</tr>

							<tr v-for="item in instrumentationInfo" :key="item.id" class="no-hover">
								<td>{{ formatPacificDate(item.created) }}</td>
								<td>{{ item.correlation_uuid }}</td>
								<td>{{ item.provider_name }}</td>
								<td>{{ item.instance_name }}</td>
								<td>{{ item.disabled ? '' : '✓' }}</td>
							</tr>
						</table>
					</div>
				</section>
			</transition>
		</section>
	</section>
</template>



<script>
import util from '@/util'

export default {
	name: 'UserInstrumentationInfo',
	store: ['users'],

	data() {
		return {
			activeId: null,
			loading: false,
			instrumentationInfo: null,
			message: 'There\'s an issue loading user instrumentation info. Please try again.'
		}
	},

	computed: {
		user() {
			return this.users[this.activeId] || {
				user_uuid: this.$route.query.uuid,
				name: null
			}
		},
		entryNumber() {
			return this.instrumentationInfo.length || 0
		}
	},

	created() {
		window.addEventListener('keyup', this.handleKeyup)
		this.loadInstrumentationInfo()
	},

	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	watch: {
		'$route': {
			handler() {
				this.activeId = this.$route.params.id
			},
			immediate: true
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		loadInstrumentationInfo() {
			this.loading = true
			this.$http.get(`/admin/${ this.user.user_uuid }/instrumentation-info`)
				.then(res => this.instrumentationInfo = res.data)
				.catch(error => this.message = error)
				.finally(() => this.loading = false)
		},

		handleKeyup(e) {
			if (e.keyCode === 27 || e.key === 'Escape') this.closeView() // escape key
		},

		formatPacificDate(date) {
			return util.formatPacificDate(date)
		},
	}
}
</script>
