import Vue from 'vue'

export default function() {
	Vue.directive('focus', {
		inserted: function (el, options) {
			if (options.value === false) return
			el.focus()
		}
	})
}
