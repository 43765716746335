<template>
	<section class="color-palette">
		<img :src="proxiedbackgroundUrl" hidden ref="colorPaletteHiddenPlaceholder" crossorigin="anonymous"/>
		<p v-if="colors.length === 0" class="loading">Loading Color Palette…</p>
		<ul v-else>
			<li v-for="color in colors" :key="color.name"
				:style="{ 'background-color': selectedColor.name === color.name ? selectedColor.hsla : color.hsla, 'border-color': overrideBorder ? overrideBorder : selectedColor.name === color.name ? selectedColor.bodyTextColor : color.bodyTextColor}"
				:class="{'u--selected': selectedColor.name === color.name}"
				@click="$emit('color-change', color);$emit('color-change-manual', color)"
				@dblclick="$emit('picker-active', true)">
			</li>
			<verte-color-picker :active="pickerActive" :color="selectedColor" @close="$emit('picker-active', false)"></verte-color-picker>
		</ul>
	</section>
</template>

<script>
import * as Vibrant from 'node-vibrant'
import ColorThief from 'colorthief/dist/color-thief'
import VerteColorPicker from '../shared/VerteColorPicker'

export default {
	name: "ColorPalette",
	props: {
		background: Object,
		selectedColor: { type: Object, default: () => {} },
		overrideBorder: { type: String, default: null },
		variant: { type: String, default: 'ColorTheif' },
		numberOfColors: { type: Number, default: 9 },
		submission: Boolean,
		pickerActive: Boolean
	},
	components: {
		VerteColorPicker
	},
	data() {
		return {
			colors: [],
			backgroundUrl: '',
		}
	},
	created() {
		this.setBackgoundUrl()
	},
	mounted() {
		this.createColorPalette(this.background)
	},
	watch: {
		background(){
			this.colors = []
			this.setBackgoundUrl()
			this.createColorPalette(this.background)
		},
	},
	methods: {
		setBackgoundUrl(){
			const googleProxyURL = 'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
			let backgroundUrl =  this.background.download_url ? this.background.download_url : this.background.large_url
			if (!backgroundUrl) backgroundUrl = this.background.preview_url ? this.background.preview_url : this.background.thumbnail_url
			this.backgroundUrl = backgroundUrl
			this.proxiedbackgroundUrl = this.submission ? backgroundUrl : googleProxyURL + backgroundUrl
		},
		createColorPalette(background) {
			switch (this.variant) {
				case 'Vibrant':
					this.createVibrantColorPalette(background)
					break
				default:
					this.createColorThiefColorPalette(background)
					break
			}
		},
		createVibrantColorPalette(background) {
			if (background.background_guid !== this.background.background_guid) {
				return;
			} else if (background && background.colorsVibrant) {
				this.setFirstColor(background)
			} else if (background && !background.colorsVibrant) {
				Vibrant.from(this.proxiedbackgroundUrl)
					.getPalette()
					.then(palette => {
						background.colorsVibrant = Object.keys(palette).map(swatch => {
							const hsl = palette[swatch].getHsl()
							const rgb = palette[swatch].getRgb()
							return {
								name: swatch,
								hex: `${palette[swatch].getHex()}e6`,
								rgba:`rgba(${rgb[0].toFixed(0)}, ${rgb[1].toFixed(0)}, ${rgb[2].toFixed(0)}, 0.95)`,
								hsla: `hsla(${(hsl[0] * 360).toFixed()}, ${(hsl[1] * 100).toFixed()}%, ${(hsl[2] * 100).toFixed()}%, 0.9)`,
								titleTextColor: palette[swatch].getTitleTextColor(),
								bodyTextColor: palette[swatch].getBodyTextColor()
							}
						})
						this.setFirstColor(background)
					})
			}
		},
		createColorThiefColorPalette(background) {
			if (background && background.colors) {
				this.setFirstColor(background)
			} else if (background && !background.colors) {
				const img = this.$refs.colorPaletteHiddenPlaceholder
				img.background_guid = background.background_guid
				const colorThief = new ColorThief()
				this.onLoadImg(img).then(() => {
					this.parseColors(colorThief.getPalette(img, this.numberOfColors), background)
					this.setFirstColor(background)
				})
			}
		},
		onLoadImg(img){
			return new Promise(resolve => { img.addEventListener('load', resolve) })
		},
		parseColors(colors, background){
			if (background.background_guid !== this.background.background_guid) {
				return;
			}
			background.colors = colors.map((color) => {
				const hsl = Vibrant.Util.rgbToHsl(color[0], color[1], color[2])
				return {
					rgba:`rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.95)`,
					hex: Vibrant.Util.rgbToHex(color[0], color[1], color[2]) + 'f2',
					name: Vibrant.Util.rgbToHex(color[0], color[1], color[2]),
					hsla: `hsla(${(hsl[0] * 360).toFixed()}, ${(hsl[1] * 100).toFixed()}%, ${(hsl[2] * 100).toFixed()}%, 0.95)`,
				}
			})
		},

		setFirstColor(background) {
			if (background.background_guid !== this.background.background_guid) {
				return;
			}

			switch (this.variant) {
				case 'Vibrant':
					this.colors = background.colorsVibrant
					break
				default:
					this.colors = background.colors
					break
			}

			if (!background.widget_color || background.widget_color === 'null') {
				this.$emit('color-change', this.colors[0])
			} else if (background.widget_color) {
				try {
					this.$emit('color-change', JSON.parse(background.widget_color))
				} catch (e) {
					this.$emit('color-change', background.widgetColor)
				}
			}
		},
	}
}
</script>

<style scoped>
	.color-palette { padding-top: 5px; margin-right:10px; }
		.color-palette ul { display: block; list-style: none; padding: 0; }
		.color-palette li { flex: 1; margin: 0.2em; text-align: center; cursor: pointer;  height: 49px; width: 49px; border-radius: 49%; float: left; }



	.u--selected { border: solid white 1px; }
</style>
