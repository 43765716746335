<template>
	<section class="color-palette">
		<ul>
			<li v-for="color in colors" :key="color.name"
				:style="{ 'background-color': selectedColor.name === color.name ? selectedColor.hsla : color.hsla, 'border-color': selectedColor.hsla === color.hsla ? selectedColor.bodyTextColor : color.bodyTextColor }"
				:class="{'u--selected': selectedColor.name === color.name}"
				@click="$emit('color-change', color);$emit('color-change-manual', color)"
				@dblclick="$emit('picker-active', true)">
			</li>
			<verte-color-picker :active="pickerActive" :color="selectedColor" @close="$emit('picker-active', false)"></verte-color-picker>
		</ul>
	</section>
</template>

<script>
import 'verte/dist/verte.css'
import VerteColorPicker from '../shared/VerteColorPicker'

export default {
	name: "DraftColors",
	props: {
		background: Object,
		selectedColor: { type: Object, default: () => {} },
		pickerActive: Boolean
	},
	components: {
		VerteColorPicker
	},
	watch: {
		selectedColor () {
			this.background.saved_colors = this.colors
		},
	},
	computed: {
		colors() {
			// if it is already an array it has been handled, return early
			if (Array.isArray(this.background.saved_colors)) return this.background.saved_colors;

			if (this.background.saved_colors) {
				return JSON.parse(this.background.saved_colors)
			}
			else {
				let drafts = Array.apply(null, new Array(9)).map(() => { return { hsla: 'hsla(0, 0%, 50%, 0.95)' } })
				for (let i = 0; i < drafts.length; i++) {
					drafts[i].name = `momo-${i}`
				}
				return drafts
			}
		},
	},
}
</script>

<style scoped>
	.color-palette { padding-top: 5px; margin-right:10px; }
		.color-palette ul { display: block; list-style: none; padding: 0; }
		.color-palette li { flex: 1; margin: 0.2em; text-align: center; cursor: pointer;  height: 49px; width: 49px; border-radius: 49%; float: left; }

	.color-picker-wrapper { position: absolute; top: 124px; left: 500px; display: block; cursor: default;  }

		.close-verte { position: absolute; bottom: 120px; right: 1px; display: block; cursor: pointer;}

		.verte { --margin: 5px; }
			.verte >>> .verte__guide { height: auto; width: auto; }
				.verte >>> .verte__menu { position: absolute; right: 100%; left: auto; z-index: 2; transform: translateY(-50%) !important; background-color: #282828; }

					.verte >>> .verte-picker__origin { border-top-right-radius: var(--border-radius); border-top-left-radius: var(--border-radius); }

					.verte >>> .verte-picker { margin-bottom: 0; }
						.verte >>> .verte-picker__slider { margin: var(--margin); margin-bottom: 0; }

					.verte >>> .verte__controller { padding: 2px var(--margin) 8px; }
						.verte >>> .verte__controller .slider { margin-bottom: 0; }
						.verte >>> .verte__input { color: rgb(200, 200, 200); }
							.verte >>> .verte__submit { visibility: hidden; }

	.u--selected { border: solid white 1px; }
</style>
