import Vue from 'vue'
import VueRouter from 'vue-router'
import VueStash from 'vue-stash'
import Axios from 'axios'
import App from './App'
import store from './store'
import VueTimeago from 'vue-timeago'
import VeeValidate from 'vee-validate'
import VueClipboard from 'vue-clipboard2'
import focus from './directives/Focus'
import clickOutside from './directives/ClickOutside'
import '../assets/css/ionicons.min.css'
import '../assets/css/bootstrap-grid.min.css'
import '../assets/css/bootstrap-grid.min.css'
import '../assets/css/style.css'

const baseURL = process.env.VUE_APP_BASE_API_URI

let axios = Axios.create({
	baseURL: baseURL,
	timeout: 300000 // 5 minutes
})

axios.interceptors.request.use(function (config) {
	if (
		(!config.url.startsWith('http://') && !config.url.startsWith('https://')) ||
		(config.url.startsWith(config.baseURL) && !config.url.startsWith('user/authenticate'))
		&& !config.noAuth
	) {
		config.withCredentials = false

		const token = window.localStorage.getItem('token')
		if (token) {
			config.headers = { 'Authorization': 'Bearer ' + token }
		}
	}
	return config
}, function (error){
	return Promise.reject(error)
})

Vue.prototype.$http = axios
focus()
clickOutside()

Vue.use(VueRouter)
Vue.use(VueStash)
Vue.use(VeeValidate)
Vue.use(VueClipboard)
Vue.use(VueTimeago, { locale: 'en' })

const router = new VueRouter({
	routes: []
})

new Vue({
	router: router,
	data: { store },
	props: { baseURL },
	render: h => h(App)
}).$mount('#app')
