<template>
	<div class="detail">
		<div v-if="!loaded" key="1" class="loading">Loading...</div>
		<div v-else key="2">
			<header class="detail-header">
				<div>Experiment: {{ experiment.title }}</div>
				<i class="detail-header-icon hide detail-hide" @click="closeView()">✕</i>
			</header>
			<section :class="{ 'nested': nestedDetail}">
				<h1>Variants' probability of winning:</h1>
				<div v-for="(prop, index) in props" :key="index">
					<header class="">{{ prop.name }} Comparisons:</header>
					<div class="compare-result">
						<div class='viz' :id="'compareViz-' + prop.id"></div>
						<table v-if="results">
							<tr v-for="result in results[prop.id]" :key="result.a+result.b">
								<td class="variant">{{ result.b }}</td>
								<td class="beating">beating</td>
								<td class="variant">{{ result.a }}</td>
								<td class="probability">: {{ result.prob }}%</td>
							</tr>
						</table>
					</div>
				</div>
				<h1>Absolute rates:</h1>
				<button v-for="(prop, key) in props" :key="key" @click="update(prop.id)" :class="{ active:activeRateViz === prop.id }">{{ prop.name }}</button>
				<div class="viz" id="rateViz"></div>
				<header class="raw-data">Raw data:</header>
				<table>
					<tr>
						<th class="center" colspan="3">Variant</th>
						<th v-for="(prop, key) in props" :key="key" class="center" colspan="2">{{ prop.name }}</th>
						<th class="center" colspan="3">Total</th>
					</tr>
					<tr class="sub-headers">
						<th class="beating" colspan="3">&nbsp;</th>

						<th class="beating center">Conversion rate</th>
						<th class="beating center">Converted</th>

						<th class="beating center">Stay rate</th>
						<th class="beating center">Stayed</th>

						<th class="beating center">Stay rate</th>
						<th class="beating center">Stayed</th>
					</tr>
					<tr class="raw-data-values" v-for="variant in variants" :key="variant.name">
						<td class="variant" colspan="3">{{ variant.name }}</td>
						<span v-for="prop in props" :key="prop.id">
							<td class="probability center">{{ variant[prop.id].rate }}%</td>
							<td class="variant center">{{ variant[prop.id].success }}</td>
						</span>
						<td class="variant center" colspan="3"> {{ formatNumber(variant[props[0].id].fail +  variant[props[0].id].success) }}</td>
					</tr>
				</table>

				<button @click="updateExperiment({archived: !experiment.archived})">{{ experiment.archived ? 'Re-activate' : 'Archive experiment' }}</button>

				<div v-if="!confirmDelete">
					<button @click="confirmDelete = true" title="Delete experiment." class="u--warning"><i class="fa fa-trash"></i> Delete Experiment</button>
				</div>
				<div class="detail-row-notes" v-else>
					<p class="u--action">You are about to delete {{ experiment.title }}. Are you sure?</p>
					<div >
						<button @click="updateExperiment({deleted: true})" class="u--warning">Yes</button>
						<button @click="confirmDelete = false" >No</button>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import cephes from 'cephes'
import * as d3 from 'd3'
import util from '@/util'

export default {
	name: 'experiment-detail',
	props: ['experiment'],
	store: ['experiments'],
	data() {
		return {
			activeRateViz: null,
			rawResults: {},
			props: [{id:'conversion', name: "Conversion"}, {id:'churn', name: "Retention (4 days)"}, {id:'churn7', name: "Retention (7 days)"}],
			confirmDelete: false,
			error: false,
			loaded: false
		}
	},
	watch: {
		experiment: {
			handler() {
				this.checkNested()
				if (this.experiment && this.experiment.experimentId){
					this.$http.get('/admin/instrumentation/experiments/'+this.experiment.experimentId)
						.then((response) => {
							this.loaded = true
							this.rawResults = response.data
							//render needs to happen so that the visualize function can find the elements to hook into
							this.$nextTick(() => this.visualize() )
						})
						.catch((error) => {
							this.loaded = true
							console.log(error)
						})
				}
			},
			immediate: true
		}
	},
	async created() {
		await cephes.compiled;
	},
	computed: {
		variants() {
			let variants =[]
			if (!this.rawResults.conversion) return variants
			for (let property of this.props.map(prop => prop.id)){
				this.rawResults[property].forEach(item => {
					let variant = variants.find(v => v.name === item.variantName)
					if (!variant) {
						variant = {
							name: item.variantName,
							churn: {success: 0, fail: 0},
							churn7: {success: 0, fail: 0},
							conversion: {success: 0, fail: 0}
						}
						variants.push(variant)
					}
					if (item.success) variant[property].success = item.users
					else variant[property].fail = item.users
				})

				variants.forEach(variant => {
					variant[property].rate = ((variant[property].success * 100.0) / (variant[property].success + variant[property].fail)).toFixed(2)
				})
			}
			variants = variants.sort((r1, r2) =>  r2.conversion.rate - r1.conversion.rate )
			return variants
		},
		results() {
			const result = {
				churn7: [],
				churn: [],
				conversion: []
			}
			if( this.variants.length === 0) return result
			for (let property of this.props.map(prop => prop.id)){
				const len = this.variants.length
				if (len < 2) return;
				for (let a = 0; a < len; a++){
					for (let b = 0; b < len; b++){
						if (a === b) continue
						let prob = bBeatsA(this.variants[a][property].success, this.variants[a][property].fail, this.variants[b][property].success, this.variants[b][property].fail)
						if (prob > 0.5) result[property].push ({a: this.variants[a].name, b: this.variants[b].name, prob: Math.round(prob * 100)})
					}
				}
				result[property] = result[property].sort((r1, r2) => r2.prob - r1.prob)
			}
			return result

			function bBeatsA (convertA, notConvertA, convertB, notConvertB) {
				const [ alphaA, alphaB ] = [ convertA + 1, notConvertA + 1]
				const [ betaA, betaB ] = [ convertB + 1, notConvertB + 1]

				let total = 0
				for (let j = 0; j < betaA; ++j) {
					total += Math.exp(cephes.lbeta(alphaA + j, alphaB + betaB) - Math.log(betaB + j) - cephes.lbeta(1 + j, betaB) - cephes.lbeta(alphaA, alphaB))
				}

				return total
			}
		}
	},
	methods: {
		checkNested() {
			this.nestedDetail = this.$route.meta.viewType === 'nestedDetail'
			this.activeId = this.$route.params.id
		},
		closeView(refreshExperiments) {
			this.$emit('viewClosed', refreshExperiments)
		},

		updateExperiment(change) {
			this.$http.patch('/admin/instrumentation/experiments/'+this.experiment.experimentId, change)
				.then(() => {
					this.closeView(true)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		visualize() {
			document.getElementsByClassName('viz').forEach(item => item.innerHTML='')
			this.rateViz = {}
			const margin = {top: 30, right: 105, bottom: 40, left: 105},
				width = 1000 - margin.left - margin.right,
				height = 200 -  margin.top - margin.bottom,
				rateVizHeight = 500 -  margin.top - margin.bottom

			this.rateViz.height = rateVizHeight
			this.rateViz.svg = d3.select("#rateViz")
				.append("svg")
				.attr("width", width + margin.left + margin.right)
				.attr('height', rateVizHeight + margin.top + margin.bottom)
				.append('g')
				.attr('transform',
					'translate(' + margin.left + ',' + margin.top + ')')

			this.rateViz.x = d3.scaleBand()
				.range([0, width])
				.domain(this.variants.map(d => d.name))
				.padding(0.2)

			this.rateViz.svg.append('g')
				.attr('transform', 'translate(0,' + rateVizHeight + ')')
				.call(d3.axisBottom(this.rateViz.x))

			this.rateViz.y = d3.scaleLinear()
				.domain([0, 100])
				.range([rateVizHeight, 0])
			this.rateViz.yAxias = this.rateViz.svg.append('g')
				.attr('class', 'myYaxis')
				.call(d3.axisLeft(this.rateViz.y))

			this.update('conversion')

			// Create chance-of-winning charts
			if (!this.results) return;
			for (let prop of this.props.map(prop => prop.id)) {
				const svg = d3.select(`#compareViz-${prop}`).append('svg')
					.attr('width', width + margin.left + margin.right)
					.attr('height', height + margin.top + margin.bottom)
					.append('g')
					.attr('transform',
						'translate(' + margin.left + ',' + margin.top + ')')

				// Add X axis
				const x = d3.scaleLinear()
					.domain([0, 100])
					.range([0, width])
				svg.append('g')
					.attr('transform', 'translate(0,' + height + ')')
					.call(d3.axisBottom(x))
					.selectAll('text')
					.attr('transform', 'translate(-10,0)rotate(-45)')
					.style('text-anchor', 'end')

				// Y axis
				const y = d3.scaleBand()
					.range([0, height])
					.domain(this.results[prop].map(d => d.b + ' > ' + d.a))
					.padding(.1)
				svg.append('g')
					.call(d3.axisLeft(y).tickFormat((d, i) => this.results[prop][i].b))
				svg.append("g")
					.attr("transform", "translate( " + width + ", 0 )")
					.call(d3.axisRight(y).tickFormat((d, i) => this.results[prop][i].a))

				//Bars
				svg.selectAll('leftBar')
					.data(this.results[prop])
					.enter()
					.append('rect')
					.attr('x', x(0))
					.attr('y', d => y(d.b + ' > ' + d.a))
					.attr('width', d => x(d.prob))
					.attr('height', y.bandwidth())
					.attr('fill', '#69b3a2')

				svg.selectAll('rightBar')
					.data(this.results[prop])
					.enter()
					.append('rect')
					.attr('x', d => -1 * (width - x(d.prob)))
					.attr("transform", "translate( " + width + ", 0 )")
					.attr('y', d => y(d.b + ' > ' + d.a))
					.attr('width', d => x(100 - d.prob))
					.attr('height', y.bandwidth())
					.attr('fill', '#dddddd')
			}
		},
		update(prop) {
			if (!this.variants || !this.variants[0][prop]) return;
			const u = this.rateViz.svg.selectAll("rect")
				.data(this.variants)
			const rates = this.variants.map(d => d[prop].rate)
			const max = Math.max(...rates)
			const min = Math.min(...rates)
			const range = Math.ceil(max - min)
			this.activeRateViz = prop
			this.rateViz.y.domain([Math.max(0, min - 10 * range), max + range ])
			this.rateViz.yAxias.transition().duration(1000).call(d3.axisLeft(this.rateViz.y))
			u.enter()
				.append("rect")
				.merge(u)
				.transition()
				.duration(1000)
				.attr("x", d => this.rateViz.x(d.name))
				.attr("y", d=> this.rateViz.y(parseFloat(d[prop].rate)))
				.attr("width", this.rateViz.x.bandwidth())
				.attr("height", d => this.rateViz.height - this.rateViz.y(parseFloat(d[prop].rate)))
				.attr("fill", "#69b3a2")
		},

		formatNumber(num) {
			return util.formatNumber(num)
		}
	}
}
</script>


<style scoped>

	.loading { margin-left: 10px; color: #aaa; font-style: italic; }
	.detail { width: auto; max-width: 100vw; }
		.detail input[type="text"] { width: 100px; margin-bottom: unset; }

	section { margin-top: 2rem; }

	.detail button { min-width: 170px; max-width: 240px; margin: 0.3rem; background: #ccc; }
		.detail button.active { background: #328b89; }
		.detail button.u--warning { background: tomato; }

	header { margin-top: 2rem; opacity: .7; }
	header.raw-data { margin-bottom: 1.5rem; }

	.compare-result { display: flex; flex-direction: column;}
	table { margin: 0 0 2rem; width: 100%; border: none; cursor: default; }
		table .sub-headers th { padding-top: 0.5rem; }
		table .raw-data-values td { padding-top: 0.5rem; }

	.compare-result table { width: auto; display: inline-block; }
		tr { border: none; }
		tr:hover { background-color: transparent; }
			td, th { padding: 1px 15px; border: none; cursor: default !important; }
				td.center, th.center { text-align: center; }

				tr:last-child td { border: none;}

				.variant { font-size: 110%; font-family: monospace; color: #328b89; letter-spacing: 0.7px; }

				.probability { font-size: 110%; font-family: monospace; color: #008885; font-weight: 600; }

				.beating { font-size: 60%; text-transform: uppercase; line-height: 100%; vertical-align: middle; }

</style>

