import Vue from 'vue'
const map = {}

export default function() {
	Vue.directive('click-outside', {
		bind: function(el, binding) {
			let downTarget, scrollbarClicked
			el.dataset.justBoundClickOutsideHandler = true
			setTimeout(() => {
				el.dataset.justBoundClickOutsideHandler = false
			}, 100)
			const inScrollbar = e => e && e.clientX > window.innerWidth
			const mousedownHandler = e => {
				scrollbarClicked = false
				if (inScrollbar(e)) {
					scrollbarClicked = true
					return
				}
				downTarget = e.target
			}
			const mouseupHandler = e => {
				if (scrollbarClicked) return
				if (
					binding.modifiers.bubble ||
					(!el.contains(downTarget) &&
						!el.contains(e.target) &&
						el !== downTarget &&
						el !== e.target &&
						el.dataset.justBoundClickOutsideHandler !== 'true')
				) {
					binding.value(e)
				}
			}
			el.dataset.clickOutsideMouseupHandlerId = Math.random().toString(36).substring(7)
			el.dataset.clickOutsideMousedownHandlerId = Math.random().toString(36).substring(7)
			map[el.dataset.clickOutsideMouseupHandlerId] = mouseupHandler
			map[el.dataset.clickOutsideMousedownHandlerId] = mousedownHandler
			document.addEventListener('mouseup', mouseupHandler)
			document.addEventListener('mousedown', mousedownHandler)
		},
		unbind: function(el) {
			if (!el || !el.dataset || !el.dataset.clickOutsideMouseupHandlerId) return
			document.removeEventListener('mouseup', map[el.dataset.clickOutsideMouseupHandlerId])
			document.removeEventListener('mousedown', map[el.dataset.clickOutsideMousedownHandlerId])
			delete map[el.dataset.clickOutsideMouseupHandlerId]
			delete map[el.dataset.clickOutsideMousedownHandlerId]
			delete el.dataset.clickOutsideMouseupHandlerId
			delete el.dataset.clickOutsideMousedownHandlerId
			delete el.dataset.justBoundClickOutsideHandler
		}
	})
}