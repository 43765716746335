<template>
	<section class="detail quote-detail" :class="{ 'nested': nestedDetail }">
		<div class="detail-wrapper">
			<header class="detail-header">
				<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>
			</header>

			<h2>Quote Details</h2>

			<p class="u--small-grey-text">Added {{ formatDate(quote.created) }}</p>
			<p class="u--small-grey-text">Saved {{ formatDate(quote.modified) }}</p>
			<div class="detail-row" @click="activeToggled">
				Active/Approve
				<span class="switch" :class="{ 'on': quote.active }"></span>
			</div>

			<div>
				<form class="edit-quote" @submit="save">
					<label for="body">Quote*</label>
					<textarea class="body-edit" type="text" name="body" v-model.trim="quote.body"></textarea>

					<label for="source">Source*</label>
					<input type="text" name="source" v-model.trim="quote.source">

					<label for="twitter">Twitter Handle</label> <span class="description">@ sign not required</span>
					<input type="text" name="twitter" v-model.trim="quote.twitter">

					<label for="notes">Notes</label>
					<textarea class="body-edit" type="text" name="notes" v-model.trim="quote.notes"></textarea>

					<button type="submit" title="Save Quote" @click.prevent="save">
						Save
					</button>
				</form>

				<button class="button-secondary u--color-danger delete-quote u--border-danger" type="submit" title="Delete Quote" @click.prevent="deleteQuote">
					Delete
				</button>
			</div>
		</div>
		<transition name="detail-nested">
			<router-view @closeNested="closeNested"></router-view>
		</transition>
	</section>
</template>

<script>
import {
	mixin as VueFocusMixin
} from 'vue-focus'
import detailsMixins from '../../mixins/detailsMixins'
import util from '../../util'

export default {
	name: 'quote-detail',

	store: ['quotes'],

	data() {
		return {
			showHeaderDropdown: false,
			editing: false,
			foundQuote: null
		}
	},

	computed: {
		quote() {
			let foundQuote = this.foundQuote = this.quotes.find(x => Number(x.id) === Number(this.activeId))
			return {
				source: foundQuote.source,
				active: foundQuote.active,
				notes: foundQuote.notes,
				twitter: foundQuote.twitter,
				body: foundQuote.body,
				id: foundQuote.id,
				created: foundQuote.created,
				modified: foundQuote.modified,
			} || {
				id: null,
				body: null,
				source: null,
				active: false
			}
		}
	},

	created() {
		this.checkNested()
	},

	mixins: [VueFocusMixin, detailsMixins],

	watch: {
		'$route' () {
			this.checkNested()
		}
	},

	methods: {
		checkNested() {
			this.$route.meta.viewType === 'nestedDetail' ? this.nestedDetail = true : this.nestedDetail = false
			this.activeId = this.$route.params.id
		},

		closeNested() {
			window.location.href = '/#/quotes/' + this.activeId
		},

		activeToggled() {
			let previousState = this.foundQuote.active
			this.foundQuote.active = !previousState

			this.$http.patch('/admin/quotes/' + this.foundQuote.id, {
				active: !previousState
			}).catch(error => {
				this.foundQuote.active = previousState
				console.log(error)
			})
		},
		save() {
			this.$http.patch('/admin/quotes/' + this.quote.id, this.quote)
				.then(() => {
					this.quote.modified = Date.now()
					const indexOf = this.quotes.indexOf(this.foundQuote)
					this.quotes.splice(indexOf, 1, this.quote)
					this.closeView()
				})
				.catch((error) => {
					console.log(error)
				})
		},

		deleteQuote() {
			if(window.confirm('Are you sure you wish to delete this Quote?')) {
				this.$http.delete('/admin/quotes/' + this.quote.id)
					.then(() => {
						const indexOf = this.quotes.indexOf(this.foundQuote)
						this.quotes.splice(indexOf, 1)
						this.closeView()
					})
					.catch(function(error) {
						console.log(error)
					})
			}
		},

		formatDate(date) {
			return util.formatDate(date)
		}
	}
}

</script>

<style>
	.edit-quote { padding-top: 30px; }
		.body-edit { resize: vertical !important; max-height: 250px; }
	.detail-row { margin-bottom: 0px !important; position: relative; }
	.delete-quote { margin-top: 10px; margin-left: 0 !important; }
</style>