<template>
	<select class="momentum-team-member-select" :value="value" @change="$emit('input', Number($event.target.value))">
		<option :value="undefined">
			---
		</option>
		<option v-for="user in users" :value="user.id" :key="user.id" :selected="value === user.id">
			{{ user.email }}
		</option>
	</select>
</template>

<script>
export default {
	name: 'MomentumTeamMemberSelect',
	props: {
		value: { type: [Number, undefined], default: undefined }
	},
	data() {
		return {
			users: [],
		}
	},
	created() {
		this.$http.get(`/team/${process.env.VUE_APP_MOMENTUM_ACCOUNT_ID}/users`)
			.then(({ data: { result: users } }) => {
				this.users = users.filter(user => !user.deleted)
			})
			.catch((error) => {
				console.error(error)
			})
	}
}
</script>

<style scoped>
.momentum-team-member-select { margin: 0; }
</style>
