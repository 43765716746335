<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
			<h2>Undelete Account</h2>
			<!--<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>-->
		</header>

		<h3>Account Details</h3>
		<strong>{{ user.name }}</strong>
		<br>{{ user.email }}
		<br>{{ user.created }}
		<br>{{ user.deleted_date }}

		<form class="search-form">

			<input type="text" name="helpticket" placeholder="Help Ticket #" class="notes">

			<input type="text" name="notes" placeholder="Notes" class="notes">

			<input type="submit" v-model="actionLabel" class="u--accent-bg" @click.prevent="doAction" v-bind:disabled="actionDisabled">
		</form>
		{{ errorMessage }}
	</section>
</template>


<script>
import {
	mixin as VueFocusMixin
} from 'vue-focus'

export default {
	name: 'user-detail-undelete',

	store: ['users'],

	data() {
		return {
			searchEmail: null,
			notes: null,
			activeId: null,
			errorMessage: null,
			actionLabel: 'Undelete',
			actionDisabled: false,
			foundUsers: []
		}
	},

	computed: {

		user() {
			return this.users[this.activeId] || {
				name: null
			}
		}

	},

	created: function() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	mixins: [VueFocusMixin],

	watch: {
		'$route'() {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		doAction() {
			let self = this
			console.log('Undeleting')
			let error = null
			if (error) {
				this.errorMessage = error
				return
			}
			this.errorMessage = null

			this.actionLabel = 'Undeleting...'
			this.actionDisabled = true

			this.$http.patch('/users/' + this.user.id + '/undelete', {})
				.then(function(response) {
					if (response.data.success) {
						self.actionLabel = 'Undelete Successful'
						self.actionDisabled = true
					} else {
						self.actionLabel = 'Undelete'
						self.actionDisabled = false
						if (response.data.default_email_in_use) {
							self.errorMessage = 'The default email address is in use on another account'
						} else if (response.data.all_emails_in_use) {
							self.errorMessage = 'All email addresses are in use on other accounts'
						} else {
							self.errorMessage = 'An error occured during undelete'
						}
					}
				})
				.catch(function(error) {
					self.actionLabel = 'Undelete'
					self.actionDisabled = false
					self.errorMessage = 'An error occured during undelete'
					console.log(error)
				})
		},

		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		}
	}
}

</script>


<style>
	.detail input.search {
		margin-top: -4px;
	}

	.detail input.notes {}

</style>
