<template>
	<div class="user-nav" v-click-outside="hide" :class="{ open: open }">

		<div class="user-row" @click="open = !open">
			<span class="user-wrapper">
				<img :src="userImg">
				<span class="user-name">{{ userInfo.display_name }}</span>
			</span>

			<span class="anim-caret">
				<i class="anim-caret-left ion-android-remove"></i>
				<i class="anim-caret-right ion-android-remove"></i>
			</span>
		</div>

		<div v-if="open" class="user-nav-actions">
			<button class="button no-look-button" @click="logout">Logout</button>
		</div>

	</div>
</template>

<script>
import md5 from 'md5'
export default {
	name: 'user-menu',
	store: ['userInfo'],
	data() {
		return {
			open: false
		}
	},
	computed: {
		userImg() {
			let email = this.userInfo && this.userInfo.email
			if (!email) return null
			return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=64&d=mm'
		}
	},
	methods: {
		hide() {
			this.open = false
		},
		logout() {
			localStorage.removeItem('token')
			localStorage.removeItem('token_uuid')
			localStorage.removeItem('display_name')
			localStorage.removeItem('email')
			this.$router.push({ path: '/login' })
		}
	}
}
</script>

<style scoped>
	.anim-caret { cursor: pointer; }

	.user-nav-actions { padding-top: 20px; padding-bottom: 20px;}
		.user-nav-actions .no-look-button { background: transparent; color: #fff; margin: 0; padding: 0;  margin-left: 20px; }
</style>
