<template>
	<div>
		<section class="content content-centered content-auth container">
			<h2><img src="../../../assets/img/logo.png" alt="momo"/></h2>

			<div class="form-message error" v-if="error"><i class="fa fa-exclamation-circle"></i> <span
				class="form-message-label"></span>{{ error }}
			</div>

			<form autocomplete="on" id="login-form" class="login-form" @submit.prevent>
				<input type="text" id="username" v-model="credentials.username" placeholder="Email" autofocus>
				<input type="password" id="password" v-model="credentials.password" placeholder="Password">

				<button class="button" @click="login" :disabled="loginLabel !== 'Login'"><span class="button-label">{{ loginLabel }}</span></button>
			</form>
			<router-link to="/recover" tag="p" class="forgot-pass">Forgot Password</router-link>
		</section>
	</div>
</template>
<script>
export default {
	data () {
		return {
			credentials: { username: '', password: '' },
			loginLabel: 'Login',
			error: null,
			config: { baseURL: this.baseURL }
		}
	},
	props : {
		baseURL: String
	},
	store: ['userInfo', 'authState'],
	methods: {
		login: function () {
			if (this.credentials.username.length === 0) {
				this.error = 'Please enter your email.'
				return
			}
			if (this.credentials.password.length === 0) {
				this.error = 'Please enter your password.'
				return
			}
			this.loginLabel = 'Logging in...'
			this.error = null
			this.$http.post('user/authenticate', this.credentials, this.config)
				.then((response) => {
					let msg = response.data
					if (msg.token) {
						window.localStorage.setItem('token', msg.token)

						if (msg.token_uuid) {
							window.localStorage.setItem('token_uuid', msg.token_uuid)
						}
						let requestParams = { timeout: 40000 }
						this.$http.get('user/profile?details=1', this.config, requestParams)
							.then((response) => {
								let msg = response.data
								this.userInfo.display_name = msg.display_name
								this.userInfo.full_name = msg.full_name

								this.$http.get('user/me', this.config)
									.then((response) => {
										let msg = response.data
										this.userInfo.email = msg.email
										this.userInfo.activeSubscription = msg.active_subscription
										this.userInfo.loggedIn = true
										this.userInfo.email = msg.email
										window.localStorage.setItem('display_name', this.userInfo.display_name)
										window.localStorage.setItem('email', this.userInfo.email)
										if (['/login', '/register'].indexOf(this.$route.path) >= 0) { // should never happen, but just in case
											this.$router.push('/')
										}
									})
							})
					} else {
						this.error = 'Your email or password is incorrect.'
					}
				})
				.catch(() => {
					this.error = 'Your email or password is incorrect.'
					this.loginLabel = 'Login'
				})
		},
		recover: function () {
			this.$router.push({ path: 'recover', query: { email: this.credentials.username } })
		}
	}
}
</script>


<style scoped>
	.forgot-pass { color: rgba(255,255,255, 0.75); cursor: pointer;}
</style>
