
import Vue from 'vue'
import store from '@/store'

export default new (Vue.extend({
	name: 'featureManager',
	data() {
		return {
			featureListLoaded: false,
			featureSetsLoaded: false,
		}
	},
	computed: {
		allFeatures() {
			if (!store.featureList.length) this.loadFeatureList()
			return store.featureList
		},
		allFeatureSets() {
			if (!store.featureSets.length) this.loadFeatureSets()
			return store.featureSets
		}
	},
	methods: {
		loadFeatureList() {
			this.$http.get(`/admin/featurelist`)
				.then(response => {
					this.$set(store, 'featureList', response.data)
					this.featureListLoaded = true
				})
				.catch((error) => {
					console.log(error)
					this.featureListLoaded = true
				})
		},
		loadFeatureSets() {
			this.$http.get('/admin/features')
				.then((response) => {
					this.$set(store, 'featureSets', response.data)
					this.featureSetsLoaded = true
				})
				.catch((error) => {
					console.log(error)
					this.featureSetsLoaded = true
				})
		},
	}
}))
