<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon detail-back" @click="closeView"></i>
			<h2>Delete Icon</h2>
			<!--<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>-->
		</header>
		<li class="full-width">
			<img class = "stock-icon" :src="icon.url">
			<div v-if="icon.id" class="stat icon-body">&ldquo;{{ icon.label }}&rdquo;</div>
		</li>
		<form>
			<input type="submit" v-model="actionLabel" class="u--accent-bg" @click.prevent="doAction" v-bind:disabled="actionDisabled">
		</form>
	</section>
</template>

<script>
	

import {
	mixin as VueFocusMixin
} from 'vue-focus'

export default {
	name: 'icon-detail-delete',

	store: ['icons'],

	data() {
		return {
			searchEmail: null,
			notes: null,
			activeId: null,
			errorMessage: null,
			actionLabel: 'Delete',
			actionDisabled: false,
			foundUsers: []
		}
	},

	computed: {
		icon: function() {
			let foundIcon = null
			if (this.icons && this.icons.length > 0) {
				this.icons.some((icon) => {
					if (icon.id == this.activeId) {
						foundIcon = icon
						return true
					}
					return false
				})
			}
			return foundIcon || {
				id: null,
				label: null,
				active: false
			}
		}
	},

	created: function() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	mixins: [VueFocusMixin],

	watch: {
		'$route'() {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		doAction() {
			console.log('Deleting icon')
			let error = null
			if (error) {
				this.errorMessage = error
				return
			}
			this.errorMessage = null

			this.actionLabel = 'Deleting...'
			this.actionDisabled = true

			let requestParams = { timeout: 40000 }
			this.$http.delete('/icons/' + this.icon.id, requestParams)
				.then(() => {
					this.actionLabel = 'Deleted'
					this.actionDisabled = true
					this.icon.active = false
					this.icon.deleted = true
					this.closeView()
				})
				.catch((error) => {
					this.actionLabel = 'Delete'
					this.actionDisabled = false
					console.log(error)
				})
		},

		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		}
	}
}

</script>


<style>
	.stock-icon { height: 35px; background: #222; }

</style>
