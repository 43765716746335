<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
			<h2>Expire Token</h2>
		</header>
		<ul>
			<li class="detail-row-notes">
				<div class="label">Token Expiry Date</div>
				<span v-if="user.expire_tokens_before">{{ formatDate(user.expire_tokens_before) }}</span>
			</li>
			<li class="line"></li>
		</ul>
		<button class="button" @click="preCheck = !preCheck">{{ expireLabel }}</button>

		<div class="pre-check" v-show="preCheck">
			<div class="label">Are you sure?</div>
			<div>Expiring a user's token will prevent them from performing actions within their account.</div>
			<button class="button danger" @click="expireToken">Yes</button>
			<button class="button" @click="preCheck = !preCheck">No</button>
		</div>
		<div>{{ message }}</div>

	</section>
</template>



<script>
import {
	mixin as VueFocusMixin
} from 'vue-focus'
import util from '@/util'

export default {
	name: 'user-expire-token',

	store: ['users'],

	data() {
		return {
			preCheck: false,
			expireInProgress: false,
			message: null
		}
	},
	computed: {
		user() {
			return this.users[this.activeId] || {
				name: null
			}
		},
		expireLabel: function() {
			return this.expireInProgress ? 'Expiring...' : 'Expire Token'
		}
	},
	created() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	mixins: [VueFocusMixin],

	watch: {
		'$route'() {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},
		expireToken() {
			this.expireInProgress = true
			let requestParams = { timeout: 40000 }
			this.$http.post('/users/' + this.activeId + '/tokens/expire', { expire: true }, requestParams)
				.then((response) => {
					this.expireInProgress = false
					this.message = 'User token successfully expired.'
					console.log(response.data)
				})
				.catch((error) => {
					this.expireInProgress = false
					this.message = error
					console.log(error)
				})
		},
		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		},
		formatDate(date) {
			return util.formatDate(date)
		}
	}
}
</script>

<style scoped>
	.button { width: 240px; }
	.pre-check { text-align: center; }
		.pre-check .button { width: 48%; }
		.pre-check .button.danger { background: #b70e0e; }
		.pre-check div { margin: 10px 0 10px 0; }
</style>
