<template>
	<div class="color-picker-wrapper" v-if="active" v-click-outside="close">
		<span title="Close Color Picker" class="close-verte" @click="close"><i class="fa fa-times"/></span>
		<verte
			:show-history="false"
			picker="square" 
			menu-position="left"
			display="widget"
			:draggable="false" 
			model="hsl"
			v-model="color.hsla">
		</verte>
	</div>
</template>

<script>
import 'verte/dist/verte.css'
import Verte from 'verte'

export default {
	name: 'VerteColorPicker',
	components: {
		Verte
	},
	props: {
		color: { type: Object, default: () => {} },
		active: Boolean,
	},
	methods: {
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style scoped>
	.color-picker-wrapper { position: absolute; left: 500px; display: block; cursor: default; }

		.close-verte { position: absolute; bottom: 120px; right: 1px; display: block; cursor: pointer; }

		.verte { --margin: 5px; }
			.verte >>> .verte__guide { height: auto; width: auto; }
				.verte >>> .verte__menu { position: absolute; right: 100%; left: auto; z-index: 2; transform: translateY(-50%) !important; background-color: #282828; }

					.verte >>> .verte-picker__origin { border-top-right-radius: var(--border-radius); border-top-left-radius: var(--border-radius); }

					.verte >>> .verte-picker { margin-bottom: 0; }
						.verte >>> .verte-picker__slider { margin: var(--margin); margin-bottom: 0; }

					.verte >>> .verte__controller { padding: 2px var(--margin) 8px; }
						.verte >>> .verte__controller .slider { margin-bottom: 0; }
							.verte >>> .verte__input { color: rgb(200, 200, 200); }
								.verte >>> .verte__submit { visibility: hidden; }
</style>
