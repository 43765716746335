<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
		</header>

		<section class="detail-nested-content">
			<div class="action-wrapper">
				Show me <input v-model="rowNumber" class="row-number-input" type="number"> entries
				<button class="button row-number-button" @click="getUserActivity">Go</button>
				<transition name="fade"><span v-if="errorMessage" class="error u--color-danger">{{ errorMessage }}</span></transition>
			</div>

			<transition name="fade">
				<span v-if="loading" class="loading">Loading...</span>
				<span v-else-if="!activityData" class="server-data-error">There's an issue loading user activity. Please try again.</span>
				<section v-else class="blocks">
					<div class="block token-info-block">
						<span class="title">Login activity &amp; Token Info</span>
						<span class="found">Found: {{ foundTokenInfoNumber }}</span>
						<table>
							<tr class="heading">
								<th>Last login</th>
								<th>Token uuid</th>
								<th>Token invalid</th>
								<th>IP address</th>
								<th>Client uuid</th>
							</tr>

							<tr v-for="item in activityData.tokenInfo" :key="item.id" class="no-hover">
								<td>{{ formatPacificDate(item.when_claimed) }}</td>
								<td>{{ item.token_uuid }}</td>
								<td>{{ item.valid ? '' : '✗' }}</td>
								<td>{{ item.ip_address }}</td>
								<td>{{ item.client_uuid }}</td>
							</tr>
						</table>
					</div>

					<div class="block">
						<span class="title">Active Day &amp; Time</span>
						<span class="found">Found: {{ foundActivityNumber }}</span>
						<table>
							<tr class="heading">
								<th>Last active</th>
							</tr>

							<tr v-for="item in activityData.activity" :key="item.id" class="no-hover">
								<td>{{ formatPacificDate(item.created) }}</td>
							</tr>
						</table>
					</div>
				</section>
			</transition>
		</section>
	</section>
</template>



<script>
import util from '@/util'

export default {
	name: 'UserActivityLog',
	store: ['users'],
	data() {
		return {
			loading: false,
			activityData: null,
			rowNumber: 20,
			errorMessage: null
		}
	},
	computed: {
		user() {
			return this.users[this.activeId] || {
				name: null
			}
		},
		foundActivityNumber() {
			return this.activityData.activity.length
		},
		foundTokenInfoNumber() {
			return this.activityData.tokenInfo.length
		}
	},
	created() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
		this.getUserActivity()
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	watch: {
		'$route'() {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		handleKeyup(e) {
			if (e.keyCode === 27 || e.key === 'Escape') this.closeView() // escape key
		},

		getUserActivity() {
			if (this.rowNumber <= 0) {
				this.errorMessage = 'Please fill in a number greater than 0.'
				return;
			} else if (this.rowNumber === null) {
				this.rowNumber = 20
			}
			this.errorMessage = null
			this.loading = true
			if (!this.user || !this.user.user_uuid) return;
			const url = `/admin/${this.user.user_uuid}/activity-log?count=${this.rowNumber}`
			this.$http.get(url)
				.then(res => this.activityData = res.data)
				.catch(error => console.log(`Error in GET ${url}: ${error}`))
				.finally(() => this.loading = false)
		},

		formatPacificDate(date) {
			return util.formatPacificDate(date)
		},

		formatTime(dateTime) {
			return util.formatTime(dateTime)
		}
	}
}
</script>


<style scoped>
	.detail-nested-content { transform: translateY(3rem); }

		th { padding: 11px 11px 11px 0; }
		.action-wrapper { margin-bottom: 1rem; }
			.detail input.row-number-input { width: 3rem; }
			.button.row-number-button { width: 4rem; padding: 8px; margin-left: .75rem; }
			.error { margin-left: 1rem; }

		.found { margin-left: 1rem; font-size: 14px; color: #777; }
		.token-info-block { flex-basis: 55%; }
</style>
