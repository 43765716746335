<template>
	<div class="detail">
		<div v-if="!loaded" key="1" class="loading">Loading...</div>
		<div v-else key="2">
			<header class="detail-header">
				<h2>{{ team.teamName }}</h2>
				<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>
			</header>
			<section class="quater-wrapper" :class="{ 'nested': nestedDetail }">
				<div class="detail-wrapper">
					<ul>
						<li >
							<div class="logo-guide"> * Click the logo to see it on other background colors</div>
							<div class="logo-wrapper">
								<div class="logo" :class="selectedBg" @click="switchLogoBg()">
									<div :style="uploadProgressStyle" class="logo-cover"></div>
									<img :src="dataUrl" >
									<div class="logo-bg"></div>
								</div>
								<div>
									<button class="button button-neutral button-smaller">
										<input type="file" multiple class="file-input" accept=".png,.svg,.gif" @change="uploadFiles">
										Set Logo
									</button>
								</div>
							</div>
						</li>
						<li class="detail-row-notes">
							<div class="label">Status</div>
							{{ status }}
						</li>
						<li class="detail-row-notes">
							<div class="label">Id</div>
							{{ team.id }} ({{ team.teamUuid }})
						</li>
						<li class="detail-row-notes">
							<div class="label">Created</div>
							{{ formatPacificDate(team.created) }}
						</li>
						<li class="detail-row-notes">
							<div class="label">Primary Admin</div>
							{{ team.primaryAdminName }}
						</li>
						<li class="detail-row-notes">
							<div>
								<span class="label">Primary Admin Email </span>
								<i class="icon icon-copy ion-ios-copy-outline u--pointer-cursor" aria-hidden="true" v-clipboard:copy="team.primaryAdminEmail" v-clipboard:success="onCopiedId.bind(this, 'emailCopied')"></i><transition name="fade"><span v-if="emailCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
								<a class="label u--pointer-cursor" @click="reinvite()"> (Re-invite) </a>
								<span>{{ sendingStatus }}</span>
							</div>
							{{ team.primaryAdminEmail }}
						</li>
						<li class="detail-row-notes" v-if="teamInfo.customer_id">
							<div>
								<span class="label">Stripe ID </span>
								<i class="icon icon-copy ion-ios-copy-outline u--pointer-cursor" aria-hidden="true" v-clipboard:copy="teamInfo.customer_id" v-clipboard:success="onCustomerIdCopied.bind(this, 'customerIdCopied')"></i><transition name="fade"><span v-if="customerIdCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
								<a title="View on Stripe" v-if="teamInfo.customer_id" v-bind:href="stripeCustomerUrl" target="_blank">
									<stripe-logo></stripe-logo>
								</a>
							</div>
							{{ teamInfo.customer_id }}
						</li>
						<li class="detail-row-notes" v-if="team.shortName">
							<div class="label">
								Short name for signup
							</div>
							<a title="Signup page" v-bind:href="signupUrl" target="_blank">
								{{ team.shortName }}
							</a>
						</li>
						<li class="detail-row-notes trial-end">
							<div class="item-row">
								<div class="label">Trial End</div>
								<div v-if="trialEndDate">{{ trialEndDate }}</div>
							</div>
							<div class="item-row has-action">
								<span>Extend trial until midnight Pacific on <input type="text" v-model="trialEndTarget" placeholder="mm/dd/yyyy" @keyup.enter="setTrialEndDate()"/></span>
								<button class="button button-neutral button-smaller" @click="setTrialEndDate()"><i class="fa fa-save"></i></button>
							</div>
							<div class="item-row has-action">
								<span>End trial in <input type="text" v-model="trialDaysRemaining" placeholder="dd" @keyup.enter="setTrialRemaining()"/> days</span>
								<button class="button button-neutral button-smaller" @click="setTrialRemaining()"><i class="fa fa-save"></i></button>
							</div>
						</li>
						<li class="detail-row-notes support-plan">
							<div class="label">Support Plan</div>
							<div v-if="team.supportPlanPrice">Current Support Plan: ${{ team.supportPlanPrice }}</div>
							<div v-else class="item-row has-action">
								<span>Add support plan: <input type="text" v-model="supportPlanPrice" placeholder="Amount (USD)"/></span>
								<button class="button button-neutral button-smaller" @click="addPlan('support')"><i class="fa fa-save"></i></button>
							</div>
						</li>
						<li class="detail-row-notes support-plan">
							<div class="label">Base Plan</div>
							<div v-if="team.basePlanPrice">Current Base Plan: ${{ team.basePlanPrice }}</div>
							<div v-else class="item-row has-action">
								<span>Add base plan: <input type="text" v-model="basePlanPrice" placeholder="Amount (USD)"/></span>
								<button class="button button-neutral button-smaller" @click="addPlan('base')"><i class="fa fa-save"></i></button>
							</div>
						</li>
						<li class="detail-row-notes">
							<div class="feature-set-label-row">
								<span class="label">Current Feature Sets</span>
								<span :class="{ 'editing': editing }" @click="editing = !editing" class="link-button">{{ editLabelAction }}</span>
							</div>
							<ul>
								<li v-for="(featureSet, index) in editableTeamFeatureSets.active" :key="index" class="feature-sets current">
									<div class="item-row">
										<div>
											<input v-if="editing" v-model="featureSet.active" type="checkbox" class="feature-sets-edit-checkbox">
											<b>{{ featureSet.featureset_name }}</b>
										</div>
										<span v-if="editing" @click="toggleExpansion('active', index)" class="link-button show-more-or-less">
											<i v-if="featureSet.expanded" class="fa fa-angle-down"></i>
											<i v-else class="fa fa-angle-left"></i>
										</span>
									</div>
									<div v-if="editing && featureSet.expanded">
										<ul>
											<li v-for="(feature, index) in featureSet.features" :key="index" class="feature-name"> {{ feature.name }} </li>
										</ul>
									</div>
								</li>
							</ul>
							<div v-if="editing" class="detail-row-notes">
								<ul>
									<li v-for="(featureSet, index) in editableTeamFeatureSets.inactive" :key="index" class="feature-sets">
										<div class="item-row">
											<div>
												<input v-model="featureSet.active" type="checkbox" class="feature-sets-edit-checkbox" :id=featureSet.featureset_name>
												<label :for=featureSet.featureset_name class="feature-sets">{{ featureSet.featureset_name }}</label>
											</div>
											<span v-if="editing" @click="toggleExpansion('inactive', index)" class="link-button show-more-or-less">
												<i v-if="featureSet.expanded" class="fa fa-angle-down"></i>
												<i v-else class="fa fa-angle-left"></i>
											</span>
										</div>
										<div v-if="editing && featureSet.expanded">
											<ul>
												<li v-for="(feature, index) in featureSet.features" :key="index" class="feature-name"> {{ feature.name }} </li>
											</ul>
										</div>
									</li>
								</ul>
								<input type="submit" :value="processLabel" @click.prevent="updateTeamFeatureSets" v-bind:disabled="inProgress">
							</div>
						</li>
						<li class="detail-row-notes">
							<div class="detail-row-notes" v-if="!softDeleteTeamConfirmation">
								<button @click="softDeleteTeamConfirmation = true" title="This will only soft delete the team, the team can be recovered and account or subscription history will not be deleted." class="u--backg-danger"><i class="fa fa-trash"></i> Delete Team</button>
							</div>
							<div class="detail-row-notes" v-else>
								<p class="u--action">You are about to delete
								<p class="u--action-danger">{{ team.teamName }}</p>
								<p class="u--action">Are you sure?</p>
								<div class="row">
									<div class="col">
										<button @click="softDeleteTeam" class="u--backg-danger"><i class="fa fa-exclamation"></i> Yes</button>
									</div>
									<div class="col">
										<button @click="softDeleteTeamConfirmation = false" class="u--backg-success"><i class="fa fa-arrow-left"></i> No</button>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<section class="blocks three-quater-wrapper" v-if="!showUsers">
				<div class="block">
					<div  :class="[ onboardingCompleted ? 'complete' : 'incomplete']" class="title">
						{{ onboardingCompleted ? 'Onboarding Complete' : 'Onboarding Not Complete' }}
					</div>
					<div v-if="!onboardingCompleted">
						<p>Last Onboarding Attempt: {{ formatPacificDate(teamInfo.last_onboarding_attempt, false) }}</p>
						<p>Current Onboarding Step: {{ teamInfo.current_onboarding_step }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Most Used Feature:
					</div>
					<p v-if="mostUsed" class="most-used">{{ mostUsed }}</p>
				</div>
				<div class="block">
					<div class="title">
						Users:  <span class="accepted-user-count">{{ teamInfo.accepted_team_count }}</span> / {{ teamInfo.team_count }}
					</div>
					<div>
						<button class="link-button" @click="showUsers = true">Show Team Users</button>
						<p class="block-detail">Admins: {{ teamInfo.invited_admins }}, <span class="accepted-user-count">{{ teamInfo.accepted_admins }} accepted </span></p>
						<p class="block-detail">Members: {{ teamInfo.invited_members }}, <span class="accepted-user-count">{{ teamInfo.accepted_members }} accepted</span></p>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_team_count }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Topics: {{ teamInfo.topics }}
					</div>
					<div>
						<p class="block-detail">Categories: {{ teamInfo.topic_categories }}</p>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_topics }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Apps: {{ teamInfo.apps }}
					</div>
					<div>
						<p class="block-detail">Categories: {{ teamInfo.categories }}</p>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_apps }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Links: {{ teamInfo.links }}
					</div>
					<div>
						<p class="block-detail">Categories: {{ teamInfo.link_categories }}</p>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_links }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Team Goals: {{ teamInfo.focuses }}
					</div>
					<div>
						<p class="block-detail">Active: {{ teamInfo.active_focuses }}</p>
						<p class="block-detail">Expired: {{ teamInfo.inactive_focuses }}</p>
						<p class="block-detail">Deleted: {{ teamInfo.delete_focuses }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Clocks: {{ teamInfo.clocks }}
					</div>
					<div>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_clocks }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Countdowns: {{ teamInfo.unexpired_countdowns + teamInfo.expired_countdowns }}
					</div>
					<div>
						<p class="block-detail">Active: {{ teamInfo.expired_countdowns }}</p>
						<p class="block-detail">Expired: {{ teamInfo.unexpired_countdowns }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Quotes: {{ teamInfo.quotes }}
					</div>
					<div>
						<p class="block-detail">Categories: {{ teamInfo.quote_categories }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Metrics: {{ teamInfo.metrics }}
					</div>
					<div>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_metrics }}</p>
					</div>
				</div>
				<div class="block">
					<div class="title">
						Subscriptions <span v-if="!subscriptionLoaded" class="loading">Loading...</span>
					</div>
					<div>
						<button @click="collectSubscriptions()" v-if="!subscription">Load subscriptions</button>
						<div v-if="!changingSubscription">
							<div v-if="subscription" class="subscription">
								{{ subscription.plan.id }} x {{ subscription.quantity }}
								<span class="link-button" @click="changingSubscription = subscription.id; isSupportPlan = false; isBasePlan = false; ">Change</span>
							</div>

							<div v-if="supportPlan">
								<hr/>
								{{ supportPlan.plan.id }}
								<span class="link-button" @click="changingSubscription = supportPlan.id; isSupportPlan = true; isBasePlan = false; ">Change</span>
							</div>
							<div v-if="basePlan">
								<hr/>
								{{ basePlan.plan.id }}
								<span class="link-button" @click="changingSubscription = basePlan.id; isBasePlan = true; isSupportPlan = false; ">Change</span>
							</div>
						</div>
						<div v-if="changingSubscription">
							<p>New {{ isSupportPlan ? 'Support' : isBasePlan ? 'Base' : 'Subscription' }} Plan</p>
							<input placeholder="new plan id" v-model="newPlanId">
							<button @click="changePlan(changingSubscription, newPlanId)">Change</button>
						</div>
					</div>
				</div>
				<!-- todo -->
				<div class="block">
					<div class="title">
						Announcements: {{ teamInfo.announcements }}
					</div>
					<div>
						<p class="block-detail">Sent: {{ teamInfo.sent_announcements }}</p>
						<p class="block-detail">Deleted: {{ teamInfo.deleted_announcements }}</p>
					</div>
				</div>
				<!-- todo -->
			</section>
			<team-users v-if="showUsers" :user-show="showUser" :team-uuid="team.teamUuid" @showingUser="showUser = true"></team-users>
		</div>
	</div>
</template>

<script>
import StripeLogo from '../users/StripeLogo'
import uploadManager from '@/managers/uploadManager'
import featureManager from '@/managers/featureManager'
import TeamUsers from './TeamUsers'
import detailsMixins from '../../mixins/detailsMixins'

export default {
	name: 'team-detail',
	components: { StripeLogo, TeamUsers },
	props: ['team'],
	store: ['teams'],
	data() {
		return {
			emailCopied: false,
			customerIdCopied: false,
			selectedBg: "grey",
			photo: { upload_completed: false },
			error: false,
			waitingForPlans: true,
			editing: false,
			inProgress: false,
			trialDaysRemaining: null,
			trialEndTarget: null,
			teamInfo: null,
			supportPlanPrice: null,
			basePlanPrice: null,
			teamAdmins: null,
			sendingStatus: '',
			showUsers: false,
			showUser: false,
			softDeleteTeamConfirmation: false,
			subscription:null,
			supportPlan:null,
			basePlan:null,
			changingSubscription: null,
			isSupportPlan: false,
			isBasePlan: false,
			subscriptionLoaded: true
		}
	},

	mixins: [ detailsMixins ],

	created() {
		this.bgClasses = ["grey", "white", "black"]
	},

	watch: {
		team: {
			handler(changed, old) {
				if (old === changed) return;
				this.supportPlanPrice = null
				this.basePlanPrice = null
				this.softDeleteTeamConfirmation = false
				this.checkNested()
			},
			immediate: true
		}
	},
	computed: {
		status() {
			if (!this.team) return ""
			const sub = this.team.subscriptionSummary;

			if (!this.team) return ""
			if (sub.trialing) return sub.hasPaymentMethod? "Trial Active: Has Card" : "Trial Active: No Card"
			if (sub.active) return "Subscription Active"
			if (sub.pastDue) return "Past Due"
			if (!sub.active && !sub.trialing && !sub.subscriptionEnd && sub.trialEnd) return "Trial Expired"
			return "Subscription Expired"
		},
		signupUrl(){
			return process.env.VUE_APP_BASE_ACCOUNT_URI + 'community/' + this.team.shortName
		},
		uploadProgressStyle() {
			return { left: this.photo.upload_completed ? '101%' : (this.photo.fileprogress + 1) + '%' }
		},
		processLabel() {
			return this.inProgress ? 'Processing...' : 'Update'
		},
		editLabelAction() {
			return this.editing ? 'CANCEL' : 'EDIT'
		},
		featureSets() {
			return featureManager.allFeatureSets
		},
		trialEndDate() {
			if (!this.team || !this.team.subscriptionSummary) return ""
			return this.formatPacificDate(this.team.subscriptionSummary.trialEnd)
		},
		editableTeamFeatureSets() {
			let active = []
			let inactive = []
			if (!this.team) return { active, inactive }

			if (!this.team.teamFeatureSets){
				this.loadTeamFeatureSets()
				return { active, inactive }
			}
			if (!this.team.teamFeatureSets.length) {
				return { active: [], inactive: this.featureSets }
			} else {
				this.featureSets.forEach(featureSet => {
					let match = this.team.teamFeatureSets.find(tfs => {
						return tfs.uuid === featureSet.id
					})
					this.$set(featureSet, 'expanded', false)
					if (match) {
						this.$set(featureSet, 'active', true)
						active.push(featureSet)
					} else {
						this.$set(featureSet, 'active', false)
						inactive.push(featureSet)
					}
				})
			}
			return { active, inactive }
		},
		dataUrl() {
			if (this.photo.file && !this.photo.dataUrl) {
				return (window.URL || window.webkit).createObjectURL(this.photo.file)
			} else {
				if (this.team && this.team.teamLogoUrl) {
					this.photo.url = this.team.teamLogoUrl
					this.photo.upload_completed = true
				}
				return this.photo.thumbnail_url || this.photo.preview_url || this.photo.large_url || this.photo.url || this.photo.dataUrl
			}
		},
		mostUsed() {
			let mostUsed = ''
			if(this.teamInfo){
				const used = {
					topics: this.teamInfo.topics,
					apps: this.teamInfo.apps,
					metrics: this.teamInfo.metrics,
					focuses: this.teamInfo.focuses,
					clocks: this.teamInfo.clocks,
					countdowns: this.teamInfo.unexpired_countdowns + this.teamInfo.expired_countdowns,
					links: this.teamInfo.links,
					quotes: this.teamInfo.quotes,
					announcements: this.teamInfo.announcements
				}
				let temp = 0;
				Object.keys(used).forEach(key => {
					if(used[key] > temp){
						temp = used[key]
						mostUsed = key.charAt(0).toUpperCase() + key.slice(1);
					}
				});
			}
			return mostUsed;
		},
		stripeCustomerUrl() {
			let url = ''
			const customerId = this.teamInfo.customer_id
			if (customerId) {
				url = this.isProduction() ? `https://dashboard.stripe.com/customers/${customerId}` : `https://dashboard.stripe.com/test/customers/${customerId}`
			}
			return url
		},
		teamUuid() {
			return this.team && this.team.teamUuid
		},
		loaded() {
			let result =  this.team && (this.error || (this.teamInfo && this.teamAdmins && !this.waitingForPlans))
			return result
		},
		onboardingCompleted() {
			return this.teamInfo.onboarding_complete
		}
	},
	methods: {
		checkNested() {
			this.nestedDetail = this.$route.meta.viewType === 'nestedDetail'
			this.activeId = this.$route.params.id
			this.showUsers = false
			this.getTeamInfo();
			this.getTeamAdmins();
		},
		closeView() {
			if (this.showUser === true){
				this.showUser = false
				this.showUsers = true
			}
			else if (this.showUsers === true){
				this.showUsers = false
			} else {
				this.$emit('viewClosed')
			}
		},
		onCopiedId(id) {
			if (id === 'emailCopied') this.emailCopied = true
			setTimeout(() => {
				this.emailCopied = false
			}, 1000)
		},
		onCustomerIdCopied(id) {
			if (id === 'customerIdCopied') this.customerIdCopied = true
			setTimeout(() => {
				this.customerIdCopied = false
			}, 1000)
		},
		reinvite() {
			this.sendingStatus= 'Sending'
			this.$http.post(`/teams/${this.teamUuid}/resend-invite`)
				.then((response) => {
					if (response.data.success) this.sendingStatus = "Sent!"
					else this.sendingStatus = "Failed!"
				})
				.catch((error) => {
					console.log(error)
					this.sendingStatus = "Failed!"
				})
		},
		collectSubscriptions() {
			this.subscriptionLoaded = false
			this.$http
				.get(`/team/${this.team.teamUuid}/subscriptions`, {days: Number.parseInt(this.trialDaysRemaining), teamId: this.team.id})
				.then((response) => {
					this.supportPlan = response.data.supportPlan
					this.basePlan = response.data.basePlan
					this.subscription = response.data.currentSubscription
					this.subscriptionLoaded = true
				})
		},
		changePlan(changingSubscription, planId) {
			this.subscriptionLoaded = false
			this.$http
				.post(`/teams/switch-plan`, {planId, isSupportPlan: this.isSupportPlan, isBasePlan: this.isBasePlan, subscriptionId: changingSubscription, teamId: this.team.id})
				.then(() => {
					this.collectSubscriptions()
					this.changingSubscription = null
				})
		},
		setTrialEndDate() {
			let today = new Date();
			today.setHours(0,0,0,0);
			this.trialDaysRemaining = Math.ceil(Math.abs(today - new Date(this.trialEndTarget)) / (1000 * 60 * 60 * 24));
			this.setTrialRemaining()
		},
		setTrialRemaining() {
			this.$http
				.post('/teams/extend-trial', {days: Number.parseInt(this.trialDaysRemaining), teamId: this.team.id})
				.then(() => {
					this.$parent.reloadData()
					this.trialDaysRemaining = ""
				})
		},
		addPlan(type) {
			this.$http
				.post(`/teams/${type}-plan`, {price: Number.parseInt(this[type + 'PlanPrice']), teamId: this.team.id})
				.then(() => {
					this.loadPlanInfo(type)
				})
		},

		switchLogoBg() {
			this.selectedBg = this.bgClasses[(this.bgClasses.indexOf(this.selectedBg) + 1) % 3]
		},
		uploadFiles(e) {
			let files = e.target.files
			if (files.length === 0) return;
			this.photo = this.submitLogo(files[0])
		},
		submitLogo: function (file) {
			uploadManager.initialize()
			if (file.type.startsWith('image/')) {
				let photo = {
					fileprogress: 0,
					file: file,
					original_filename: file.name
				}
				let submission = {
					filename: photo.file.name,
					type: photo.file.type,
					size: photo.file.size,
					teamId: this.team.id
				}
				this.$http
					.post('/teams/logo', submission)
					.then(response => {
						if (response && response.data.success) {
							let data = response.data
							photo.image_upload_uri = data.file_upload_uri
							photo.file_uuid = data.file_uuid
							uploadManager.uploadFile(photo)
							this.$set(this.team, 'teamLogoUrl', this.dataUrl);
						}
					})
					.catch(err => {
						console.log('error: ' + err)
						photo.upload_failed = true
						this.inProgress = false
					})
				return photo
			}
		},
		toggleExpansion(list, index) {
			this.editableTeamFeatureSets[list][index].expanded = !this.editableTeamFeatureSets[list][index].expanded
		},
		loadPlanInfo(type) {
			let team = this.team
			if (!team || !team.teamUuid) return;
			if (this.waitingForPlans === true) this.waitingForPlans = 1
			else this.waitingForPlans++
			this.$http.get(`/teams/${team.teamUuid}/${type}-plan`)
				.then((response) => {
					this.waitingForPlans--
					if (response.data.price !== 0) this.$set(team, type + 'PlanPrice', response.data.price)
				})
				.catch((error) => {
					console.log(error)
					this.waitingForPlans--
					this.error = true
				})
		},
		loadTeamFeatureSets(team) {
			team = team || this.team
			if (!team || !team.teamUuid) return;
			this.$http.get(`/team/${team.teamUuid}/featuresets`)
				.then((response) => {
					this.$set(team,'teamFeatureSets', response.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		updateTeamFeatureSets() {
			this.inProgress = true
			const updateActiveFeatureSets = this.editableTeamFeatureSets.active.filter(fs => fs.active).map(fs => fs.id)
			const updateInactiveFeatureSets = this.editableTeamFeatureSets.inactive.filter(fs => fs.active).map(fs => fs.id)
			let updateFeatureSets = updateActiveFeatureSets.concat(updateInactiveFeatureSets)
			const requestParams = { feature_sets: updateFeatureSets }

			this.$http.put(`/team/${this.teamUuid}/featuresets`, requestParams)
				.then((response) => {
					this.inProgress = false
					if (response.data) {
						this.$set(this.team,'teamFeatureSets', response.data)
						updateFeatureSets = []
						this.editing = false
					}
				}).catch((error) => {
					this.inProgress = false
					console.log(error)
				})
		},
		getTeamInfo() {
			if (!this.teamUuid) return;
			this.sendingStatus = ''
			this.$http.get(`/admin/query/992272e6-71db-496f-9a3d-74ca463c3b60/execute?uuid=${this.teamUuid}`)
				.then(response => {
					this.teamInfo = response.data[0]
					this.loadPlanInfo('base')
					this.loadPlanInfo('support')
				})
				.catch((error) => {
					console.log(error)
					this.error = true
				})
		},
		getTeamAdmins() {
			if (!this.teamUuid) return;
			this.$http.get(`/admin/query/0949f0b4-62e5-4c0a-8204-b6ebee3093ab/execute?uuid=${this.teamUuid}`)
				.then(response => {
					this.teamAdmins = response.data
				})
				.catch((error) => {
					console.log(error)
					this.error = true
				})
		},
		softDeleteTeam() {
			if (!this.softDeleteTeamConfirmation) return;
			this.$http.delete(`/team/${this.teamUuid}`)
				.then(() => {
					const index = this.teams.findIndex(x => x.teamUuid === this.teamUuid)
					this.teams.splice(index, 1)
					this.softDeleteTeamConfirmation = false
					this.closeView()
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
}
</script>


<style scoped>
	/* logo */
	.logo-wrapper { }
		.logo-bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 0; opacity: 0.1; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAH0lEQVQoU2NkwA7+owszDhGFGA5nYGDAcDtIYLArBABHjQoGY8/g5AAAAABJRU5ErkJggg=='); border-radius: 3px; }
		.logo-cover { position: absolute; top: -1px; right: -1px; bottom: -1px; left: -1px; z-index: 2; background: white; content: ''; opacity: 0.75; transition: all 0.2s ease; }
		.logo img { width: 100%; height: 100%; max-height: 250px; position: relative; z-index: 1; object-fit: contain; }


		.logo-wrapper .button { position: relative; }
		.logo-wrapper .file-input { margin-bottom: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; cursor: pointer; }


		.logo { position: relative; width: 100%; display: block; background: rgba(0,0,0,0.5); box-shadow: inset 0 0 5px rgba(0,0,0,0.1); vertical-align: top; border-radius: 3px; cursor: pointer; }
			.logo:hover { background: #fff; }
			.logo.grey { background: #ddd }
			.logo.black { background: #000 }
			.logo.white { background: #fff }
		.logo-guide { color: #999; font-size: 0.75em; margin-bottom: 1rem; }

	/* buttons */
	.button-smaller { min-width: 130px; margin-bottom: 2px; }
	.link-button.show-more-or-less { padding-left: 20px; }

	/* feature sets */
	.feature-set-label-row { display: flex; align-items: center; justify-content: space-between; }
		.feature-set-label-row .label { margin-bottom: unset; }

	.feature-sets { margin-bottom: 1em; }
	.feature-sets.current:first-child { margin-top: 1em; }
		.feature-sets .item-row { display: flex; align-items: center; justify-content: space-between; }
			.feature-sets-edit-checkbox { margin: 0 5px 0 0; }

		.feature-sets li.feature-name { margin: 5px 0 5px 18px; color: #999; font-size: 0.85rem; }


	/* support plan */
	.support-plan input, .trial-end input { width: 18px; border-bottom: 1px solid #444; padding: 1px; }
		.support-plan input:hover, .trial-end input:hover { background: #eee; }
	.support-plan button, .trial-end button { width: auto; min-width: auto; padding: 7px 14px; margin-left: 10px; border-radius: 5px; font-size: .75rem; }

	/* others */
	.admin-deleted { text-decoration: line-through; color: tomato}

	.trial-end-date { margin-top: 1rem; }

	/* general */
	.loading { margin-left: 10px; color: #aaa; font-style: italic; }

	.detail input[type="text"] { width: 100px; margin-bottom: 0; }
	.detail p { font-size: 16px; }
		p.most-used { text-transform: uppercase; }

		.accepted-user-count { color: #999; }
	/* .trial-end .item-row:not(:last-child) { margin-bottom: 1.3rem; } */

	hr { opacity: 0.5; }

	.u--backg-danger { background: tomato;}
	.u--backg-success { background: lightgreen;}
	.u--backg-momo { background: mediumturquoise;}

	.u--action { font-size: 0.8em; padding-bottom: 2px; text-align: center; }
	.u--action-danger { color: tomato; font-weight: bolder; font-size: 1.5em; padding-bottom: 2px; text-align: center;}

	.u--pointer-cursor { cursor: pointer; }

	.quater-wrapper { width: 25% }
	.three-quater-wrapper { width: 75%; padding-left:20px }

</style>
