<template>
	<div class="tags-row">
		<div v-if="editable">
			<input type="text" v-model.trim="tag" @keypress.enter.prevent="addTag" />
		</div>
		<ul :class="[editable ? 'tags-editable' : 'tags-not-editable']">
			<li class="tag" v-for="(tag, index) in tags" :key="index">
				<span>{{ tag }}</span>
				<span v-if="editable" class="tag-delete" @click="tags.splice(index, 1)">&times;</span>
			</li>
		</ul>
	</div>
</template>



<script>
export default {
	name: 'Tags',
	props: {
		tags: { type: Array, default: () => [] },
		editable: { type: Boolean, default: true }
	},
	data() {
		return {
			tag: ''
		}
	},
	methods: {
		addTag() {
			if (this.tag && !this.tags.includes(this.tag)) {
				this.tags.push(this.tag.toLowerCase())
				this.tag = ''
			}
		}
	}
}
</script>



<style scoped>

.tags-row { display: flex; align-items: baseline; }
	.tags-editable { display: inline; margin-left: 20px; }
		.tags-editable .tag { top: 10px; }
    .tags-not-editable { display: inline-flex; }
		
		.tag { display: block; width: max-content; height: max-content; margin: 5px 0 0; border: 1px solid #bbb; border-radius: 3px; padding: 3px 5px; color: #999; font-size: .675rem; text-transform: capitalize; }
			.tag:not(:first-child) { margin-left: 5px; }
	.tag-button { width: 10px; }
    .tag-delete { cursor: pointer; margin: 0 2.5px 0 5px; }
</style>