<template>
	<section class="detail notification-detail">
		<h4>Stats - {{ notification?.title }}</h4>
		<i class="detail-header-icon hide detail-hide" @click="closeView" title="Close">✕</i>
		<template v-if="notification?.campaign_group_server_id === null">
			<h5>No campaign group is associated with this notification.</h5>
		</template>
		<template v-else>
			<div class="stats-refresh-panel">
				<div class="stats-refresh-actions">
					<button @click="getUpdatedStats" class="button" :disabled="updatingStats || loading">Refresh Stats</button>
					<button @click="getCurrentStats" class="button button-secondary" :disabled="loading">Refresh Data</button>
				</div>
				<p>{{ timeSinceStatsUpdate }}</p>
			</div>
			<div class="stats-content">
				<route-loader v-if="updatingStats || loading" />
				<template v-if="statsAssociatedWithNotification.length">
					<div v-for="stat in statsAssociatedWithNotification" :key="stat.label" class="stat-content">
						<div v-for="(value, key) in stat" :key="key" class="stat-keys">
							<span class="key">{{ key }}:</span>
							<span class="value">{{ value }}</span>
						</div>
					</div>
				</template>
				<h5 v-else-if="updatingStats">Updating stats for this notification, please wait.</h5>
				<h5 v-else>Sorry, no stats were found for this notification.</h5>
			</div>
		</template>
	</section>
</template>

<script>
import RouteLoader from "@/components/loaders/LoadingRoutes.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Add the relative plugin
dayjs.extend(relativeTime)

/**
 * Stats response:
 * {
 *     "campaignGroupId": number,
 *     "campaignGroupName": string,
 *     "isUpdating": boolean,
 *     "lastStatsUpdateStart": string | null,
 *     "lastStatsUpdateEnd": string | null,
 *     "isStatsUpdatePending": boolean,
 *     "lastStatsUpdateRequest": string | null,
 *     "stats": { labels: string, ... }[],
 *     "totalFileSize": number,
 *     "totalImageSize": string
 * }
 */

export default {
	name: 'NotificationStats',
	components: { RouteLoader },
	store: ['notifications', 'campaignGroups'],
	data() {
		return {
			loading: false,
			stats: [],
			updatingStats: false,
			updatingStatsInterval: undefined,
			updatingStatsEnd: null,
		}
	},
	computed: {
		notificationId() {
			return Number(this.$route.params.id)
		},
		notification() {
			if (!this.notifications) return {}
			return this.notifications.find(x => x.id === this.notificationId)
		},
		timeSinceStatsUpdate() {
			if (!this.updatingStatsEnd) return 'Not previously updated'
			const date = dayjs(this.updatingStatsEnd)
			return `Refreshed ${date.fromNow()}`
		},
		statsAssociatedWithNotification() {
			if (!this.notification) return []

			// Find the stat associated with the current notification label,
			// then remove the label value so that we don't display it
			// in the stats section
			return this.stats.filter(stat => {
				const label = JSON.parse(stat.labels)[0] ?? ''
				return this.notification.labels.includes(label)
			}).map(stat => {
				// eslint-disable-next-line no-unused-vars
				const { labels, ...data } = stat
				return data
			})
		}
	},
	destroyed() {
		// Remove the interval when leaving this component
		clearInterval(this.updatingStatsInterval)
	},
	watch: {
		notification: {
			async handler() {
				if (this.loading || this.notification.campaign_group_server_id === null) return

				// Get the current stats and then, if we're updating at the moment, get the
				// stats on an interval until the stats are updated
				await this.getCurrentStats()
				if (this.updatingStats) this.getCurrentStatsOnInterval()
			},
			immediate: true,
		}
	},
	methods: {
		closeView() {
			this.$emit('viewClosed')
		},
		async getCurrentStats() {
			try {
				// Set our status as loading and fetch the stat data
				this.loading = true
				const { data } = await this.$http.get(`/admin/notifications/stats/${this.notification?.campaign_group_server_id}`)

				// Set our required data, with fallbacks
				this.stats = data.stats ?? []
				this.updatingStats = !!data.isStatsUpdatePending
				this.updatingStatsEnd = data.lastStatsUpdateEnd ?? null
			} catch (e) {
				this.stats = []
				this.updatingStats = false
				this.updatingStatsEnd = null
			} finally {
				this.loading = false
			}
		},
		async getUpdatedStats() {
			try {
				// If we're updating, prevent the update from being called again
				if (this.updatingStats) return
				this.updatingStats = true

				// Call the update and set and interval to continuously fetch the current data
				void this.$http.post(`/admin/notifications/stats/${this.notification?.campaign_group_server_id}/update`)
				await this.getCurrentStatsOnInterval()
			} catch (e) {
				console.error(e)
			}
		},
		getCurrentStatsOnInterval() {
			if (this.updatingStatsInterval) return
			this.updatingStatsInterval = setInterval(async () => {
				// Update the stats and clearing the interval if we've successfully updated them
				await this.getCurrentStats()
				if (this.updatingStats !== false) return
				clearInterval(this.updatingStatsInterval)
				this.updatingStatsInterval = undefined
			}, 10 * 1000)
		}
	}
}
</script>

<style scoped>
.detail {
	display: flex;
	flex-direction: column;
	max-height: 100vh;
}

.stats-refresh-panel {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: rgb(243, 243, 243);
}

.stats-refresh-panel p {
	opacity: 0.8;
	font-weight: bold;
	margin: 0;
	text-align: center;
}

.stats-refresh-actions {
	display: flex;
}

.stats-content {
	position: relative;
	flex-grow: 1;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.stat-content {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

.stat-keys {
	display: flex;
	gap: 0.2rem;
	justify-content: space-between;
	padding: 0 0 0.75rem;
	border-bottom: 1px solid rgb(243, 243, 243);
}

.stat-keys:last-child {
	border-bottom: none;
}

.stat-keys .key {
	font-weight: bold;
}
</style>
