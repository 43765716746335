<template>
	<div id="heatmap"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
	name: 'heatmap',
	props: {
		data: Array,
		color: String,
		rowProp: String,//creation
		rowLabel: String,//Registration week
		columnProp: String,//conversionWeek
		columnLabel: String,//conversion Week
		valueProp: String,
		formattedValueProp: String,
		valueLabel: String,//upgrades
	},

	mounted() {
		this.vis()
	},

	methods: {

		vis() {
			let data = this.data
			// set the dimensions and margins of the graph
			let margin = {top: 30, right: 30, bottom: 60, left: 90},
				width = 950 - margin.left - margin.right,
				height = 750 - margin.top - margin.bottom;

			// append the svg object to the body of the page
			// create a tooltip
			let tooltip = d3.select("#heatmap")
				.append("div")
				.attr("class", "tooltip")


			let svg = d3.select("#heatmap")
				.append("svg")
				.attr("width", width + margin.left + margin.right)
				.attr("height", height + margin.top + margin.bottom)
				.append("g")
				.attr("transform",
					"translate(" + margin.left + "," + margin.top + ")");

			// Labels of row and columns
			let rows = [...new Set(data.map(d => d[this.rowProp]))].sort((a, b) => a-b )
			let columns = [...new Set(data.map(d => d[this.columnProp]))].sort((a, b) => a-b )
			let maxValue = Math.max.apply(Math, data.map(d => d[this.valueProp]))
			let minValue = Math.min.apply(Math, data.map(d => d[this.valueProp]))
			for (let column of columns){
				for (let row of rows){
					let next = data.find(d => d[this.rowProp] === row && d[this.columnProp] === column + 1)
					let afterNext = data.find(d => d[this.rowProp] === row && d[this.columnProp] > column + 1)
					if (!next && afterNext){
						let current
						let index = 0
						while (!current && index < column){
							current = data.find(d => d[this.rowProp] === row && d[this.columnProp] === column - index)
							index++
						}
						if (!current) continue;
						let missingDatapoint = {}
						missingDatapoint[this.rowProp] = current[this.rowProp]
						missingDatapoint[this.columnProp] = column + 1
						missingDatapoint[this.valueProp] = current[this.valueProp]
						missingDatapoint[this.fromattedValueProp] = current[this.fromattedValueProp]
						missingDatapoint[this.tooltip] = current[this.tooltip]
						missingDatapoint.cohortSize = current.cohortSize
						data.push(missingDatapoint)
					}
				}
			}
			// Build X scales and axis:
			let x = d3.scaleBand()
				.range([ 0, width ])
				.domain(columns)
				.padding(0.01);
			svg.append("g")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.axisBottom(x))

			// Build X scales and axis:
			let y = d3.scaleBand()
				.range([ height, 0 ])
				.domain(rows)
				.padding(0.01);
			svg.append("g")
				.call(d3.axisLeft(y).tickFormat(c => Math.floor(c/100) + ' - ' + c%100));

			// Build color scale
			let myColor = d3.scaleLinear()
				.range(["white", this.color])
				.domain([minValue, maxValue])

			//Read the data
			svg.selectAll()
				.data(data, d => d[this.columnProp] + ':' + d[this.rowProp])
				.enter()
				.append("rect")
				.attr("x", d => x(d[this.columnProp]))
				.attr("y", d =>  y(d[this.rowProp]))
				.attr("width", x.bandwidth() )
				.attr("height", y.bandwidth() )
				.style("fill", d => myColor(d[this.valueProp]))
				.on("mouseover", mouseover)
				.on("mousemove", mousemove)
				.on("mouseleave", mouseleave)

			svg.selectAll()
				.data(data, d => d[this.valueProp])
				.enter()
				.append("text")
				.attr("text-anchor", "middle")
				.attr("dominant-baseline", "central")
				.attr("x", d => x(d[this.columnProp]) + x.bandwidth()/2)
				.attr("y", d =>  y(d[this.rowProp]) + y.bandwidth()/2)
				.text(d => d[this.formattedValueProp])
				.attr("class", "formatted-value");

			svg.append("text")
				.attr("transform",
					"translate(" + (width/2) + " ," +
						(height + margin.top + 20) + ")")
				.style("text-anchor", "middle")
				.text(this.columnLabel);

			svg.append("text")
				.attr("transform", "rotate(-90)")
				.attr("y", 0 - margin.left)
				.attr("x",0 - (height / 2))
				.attr("dy", "1em")
				.style("text-anchor", "middle")
				.text(this.rowLabel);



			// Three function that change the tooltip when user hover / move / leave a cell
			function mouseover() {
				tooltip.style("opacity", 1)
			}
			function mousemove(d) {
				if (!d) return
				// fixme: hover tooltip
			}
			function mouseleave() {
				tooltip.style("opacity", 0)
			}

		},
	}
}
</script>


<style>
	.tooltip {
		position: absolute;
		opacity: 0;
		padding: 7px;
		font-size: 12px;
		color: white;
		background: rgb(14, 97, 83);
		border-radius: 8px;
		pointer-events: none;
	}
	.formatted-value{
		font-size: 12px;
	}
</style>
