<template>
	<section class="detail-nested">
		<header>
			<i class="ion-android-arrow-back detail-header-icon" @click="closeView"></i>
			<h2>Hard delete user request confirmation</h2>
		</header>
		<button class="button" @click="preCheck = !preCheck">{{ expireLabel }}</button>
		<div class="pre-check" v-show="preCheck">
			<div class="label">Are you sure?</div>
			<div>This will send an email to the user to confirm complete hard deletion of their account.</div>
			<button class="button danger" @click="askUser(true)">Yes</button>
			<button class="button" @click="preCheck = !preCheck">No</button>
		</div>
		<div>{{ message }}</div>

	</section>
</template>



<script>
import util from '@/util'

export default {
	name: 'user-delete',

	store: ['users'],

	data() {
		return {
			preCheck: false,
			sending: false,
			message: null
		}
	},
	computed: {
		user() {
			return this.users[this.activeId] || {
				user_uuid: null
			}
		},
		expireLabel: function() {
			return this.sending ? 'Sending...' : 'Send deletion link to the user'
		}
	},
	created() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	watch: {
		'$route' () {
			this.activeId = this.$route.params.id
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		askUser(send) {
			this.message = null
			if (send) this.sending = true
			let requestParams = { timeout: 40000 }
			this.$http.post('/users/' + this.user.user_uuid + '/hardDeleteRequest', { send: send }, requestParams)
				.then((response) => {
					this.sending = false
					if (!send) this.message = response.data.url
					else this.message = 'Request is sent!'
					this.preCheck = false
				})
				.catch((error) => {
					this.sending = false
					this.message = error
					console.log(error)
				})
		},
		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		},
		formatDate(date) {
			return util.formatDate(date)
		}
	}
}
</script>

<style scoped>
	.button { margin-bottom: 10px; }
	.pre-check { text-align: center; }
		.pre-check .button { width: 48%; }
		.pre-check .button.danger { background: #b70e0e; }
		.pre-check div { margin: 10px 0 10px 0; }
</style>
