<template>
	<section class="detail-nested edit-Icon">
		<header>
			<i class="ion-android-arrow-back detail-header-icon detail-back" @click="closeView"></i>
			<h2>Edit Icon</h2>
			<!--<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>-->
		</header>
		<form>
			<label for="label">Icon</label>
			<textarea name="label" class="edit-icon-label" v-model="icon.label"></textarea>

			<label for="tags">Tags</label>
			<textarea type="text" name="tags" v-model="icon.tags"></textarea>


			<input type="submit" v-model="actionLabel" class="u--accent-bg" @click.prevent="doAction" v-bind:disabled="actionDisabled">
		</form>
	</section>
</template>

<script>
	

import {
	mixin as VueFocusMixin
} from 'vue-focus'

export default {
	name: 'Icon-detail-edit',

	store: ['icons'],

	data() {
		return {
			searchEmail: null,
			tags: null,
			activeId: null,
			errorMessage: null,
			actionLabel: 'Save',
			actionDisabled: false,
			updatedProperties: []
		}
	},

	computed: {
		icon: function() {
			let foundIcon = null
			if (this.icons && this.icons.length > 0) {
				this.icons.some((icon) => {
					if (icon.id == this.activeId) {
						foundIcon = icon
						return true
					}
					return false
				})
			}
			return foundIcon || {
				id: null,
				tags: null,
				active: false
			}
		}
	},

	created: function() {
		window.addEventListener('keyup', this.handleKeyup)
		this.activeId = this.$route.params.id
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},

	mixins: [VueFocusMixin],

	watch: {
		'$route'() {
			this.activeId = this.$route.params.id
		},
		activeId(oldVal, newVal) {
			if (oldVal != newVal) {
				this.updatedProperties = []
			}
		},
		'icon.label'() {
			this.modelChanged('label')
		},
		'icon.tags'() {
			this.modelChanged('tags')
		}
	},

	methods: {
		closeView() {
			this.$emit('closeNested')
		},

		modelChanged(property) {
			if (this.updatedProperties.length > 0) {
				let exists = this.updatedProperties.some(function(prop) {
					if (prop == property) {
						return true
					}
					return false
				})
				if (exists) return
			}
			this.updatedProperties.push(property)
		},

		doAction() {
			if (this.updatedProperties.length == 0) return

			let error = null
			if (error) {
				this.errorMessage = error
				return
			}
			this.errorMessage = null

			let updatedInfo = {}
			this.updatedProperties.forEach((prop) => {
				updatedInfo[prop] = this.icon[prop]
			})

			this.actionLabel = 'Saving...'
			this.actionDisabled = true

			let requestParams = { timeout: 40000 }
			this.$http.patch('/icons/' + this.icon.id, updatedInfo, requestParams)
				.then(() => {
					this.actionLabel = 'Save'
					this.actionDisabled = false
					this.updatedProperties = []
					this.closeView()
				})
				.catch((error) => {
					this.actionLabel = 'Save'
					this.actionDisabled = false
					console.log(error)
					this.errorMessage = 'Error saving icon'
				})
		},

		handleKeyup(e) {
			if (e.keyCode === 27) this.closeView() // escape key
		}
	}
}

</script>


<style>
	.detail input.search {
		margin-top: -4px;
	}

	.detail input.tags {}

</style>
