<template>
	<div class="app" id="app">
		<admin-header v-if="userInfo.loggedIn"></admin-header>
		<section v-if="!userInfo.loggedIn && unsecured.indexOf($route.path)<0">
			<login class="login" :base-u-r-l="baseURL"></login>
		</section>
		<section :class="[sectionClass, {body: userInfo.loggedIn}, {noScroll: preview.show}]" v-else>
			<router-view>
				<loading-routes slot="loader"></loading-routes>
			</router-view>
		</section>
	</div>
</template>


<script>
import routes from './routes'
import AdminHeader from './components/AdminHeader'
import Login from './components/login/Login'
import LoadingRoutes from './components/loaders/LoadingRoutes'

export default {
	name: 'app',

	components: {
		AdminHeader,
		Login,
		LoadingRoutes
	},

	props: {
		baseURL: String
	},

	store: ['userInfo', 'authState', 'preview'],

	data() {
		return {
			sectionClass: '',
			unsecured: ['/recover', '/logout', '/link-expired', '/onetime']
		}
	},

	created() {
		let self = this
		this.$router.beforeEach((to, from, next) => {
			if(to.meta && to.meta.title)
				document.title = to.meta.title
			else
				document.title = "Momentum Admin"
			// to and from are both route objects
			if (to.meta && to.meta.bodyClass)
				self.sectionClass = to.meta.bodyClass
			else self.sectionClass = 'body'
			next()
		})
		this.$router.addRoutes(routes.definitions())

		if (window.localStorage.getItem('token')) {
			this.userInfo.loggedIn = true
			this.userInfo.email = window.localStorage.getItem('email')
			this.userInfo.display_name = window.localStorage.getItem('display_name')
		}
	}
}
</script>
