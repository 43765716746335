import util from '@/util'

export default {
	store: ['users', 'teams', 'quotes'],
	data() {
		return {
			nestedDetail: false,
			activeId: null
		}
	},
	created() {
		window.addEventListener('keyup', this.handleKeyup)
	},
	destroyed() {
		window.removeEventListener('keyup', this.handleKeyup)
	},
	methods: {
		handleKeyup(e) {
			// escape key
			if (e.keyCode === 27 || e.key === 'Escape') this.closeView()
		},

		formatDate(date) {
			return util.formatDate(date)
		},

		formatPacificDate(date, timezone = true) {
			return util.formatPacificDate(date, timezone)
		},

		isProduction() {
			return util.isProduction()
		},

		closeView() {
			this.$emit('viewClosed')
		}
	}
}
